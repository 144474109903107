.section_no_background {
  width: 1200px;
  position: relative;
  margin: auto;
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}


.top_banner {
  background-color: #f8d210;
  background-image: url("../../../images/bg/Group720049.png");
  background-repeat: no-repeat;
  background-position: top 44px right 69px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 64px 0 101px;
  margin-top: 120px;
  width: 100%;
  align-items: flex-start;
}

.banner_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  margin-top: 64px;
}

.tag {
  padding: 8px 16px;
  width: fit-content;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
}

.banner_text {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 492px;
}

.banner_title {
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  color: #000000;
}

.banner_message {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}

.banner_button {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  border: 2px solid #000000;
  transition: all 0.8s;
}

.btn span {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btn span:hover {
  font-weight: 700;
}

.enrol_now {
  background: #000000;
  color: #ffffff;
  width: 133px;
  height: 46px;
}

.download_brochure {
  background: none;
  color: #000000;
  width: 203px;
  height: 46px;
}

.download_brochure:disabled {
  background: #d8d8d8;
  border: 2px solid #d8d8d8;
}

/* .download_brochure:hover {
  font-weight: 500;
} */

.banner_photo img {
  margin-top: 10px;
}

.first_course_inf {
  position: absolute;
  width: 100%;
  padding: 0 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  bottom: -50px;
}

.inf_point {
  width: 237.18px;
  height: inherit;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
  border-radius: 4px;
}

.point {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
  align-self: stretch;
}

.select_cont {
  margin-top: 8px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background: rgba(0, 0, 0, 0.04);
}

.arrow {
  width: 12px;
  margin-right: 8px;
}

.selected_arrow {
  transform: rotate(180deg);
}

.select {
  width: 100%;
  padding: 8px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  color: #000;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
}

.select span {
  text-align: center;
}

.dropdown {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  color: #1E1B2E;
  background: #fff;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 12px;
  padding-left: 16px;
  color: #000;
  cursor: pointer;
}

.dropdown:hover {
  font-weight: 500;
  font-size: 14.5px;
  line-height: 14.5px;
}

.selected {
  background: #F5F5EF;
}

.select_options {
  width: 100%;
  position: absolute;
  top: 105%;
  background: rgba(0, 0, 0, 0.04);
}

.select_dropdown {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.info {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1e1b2e;
  align-self: stretch;
  margin-bottom: 4px;
}

.point_description {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #333333;
}

@media only screen and (max-width: 1200px) {
  .section_no_background {
    width: calc(100vw - 24px);
  }
}

@media only screen and (max-width: 992px) {
  .section_no_background {
    width: 100vw;
  }

  .top_banner {
    background-image: none;
    background-color: #f8d210;

    flex-direction: column;
    align-items: center;
    padding: 64px 16px 0 16px;
    margin-top: 0px;
  }

  .banner_photo {
    width: 375px;
    overflow: hidden;
    background-image: url("../../../images/bg/Group720049.png");
    background-position: bottom center;
    background-repeat: no-repeat;
  }

  .banner_photo img {
    width: 100%;
    height: 100%;
  }

  .banner_info {
    width: 343px;
  }

  .banner_title {
    font-size: 35px;
    line-height: 42px;
  }

  .banner_message {
    font-size: 18px;
    line-height: 27px;
  }

  .banner_button {
    justify-content: space-between;
    flex-direction: column;
  }

  .banner_button button {
    width: fit-content;
  }

  .enrol_now {
    padding: 16px 44px;
  }

  .download_brochure {
    padding: 16px 40px;
  }

  .first_course_inf {
    width: 343px;
    margin-left: calc(50vw - 176px);
    padding: 0;
    bottom: -204px;
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(2, 1fr);
  }

  .inf_point {
    width: 168px;
    height: 100%;
    padding: 16px 24px;
  }
}
