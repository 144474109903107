.yellow_bg{
    background: #F8D210;
    border-radius: 4px;
    width: 1200px;
    height: 188px;
    display: flex;
    justify-content: center;
    align-items: center;
}
html {
  scroll-behavior: smooth;
  background: #E5E5E5;
}

.section_center{
      display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8rem;
    margin-bottom: 8rem;
}
.bg_heading{
  color: #000000;
  text-align: center;
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 50px;
line-height: 60px;
width: 674px;
height: 60px;
margin: 0 auto;
}
.main_contents{
  display: flex;
    width: 1200px;
    align-items: center;
    margin: 0 auto;
    gap: 3rem;
}

.underline {
  text-decoration: underline;
}
.text_div{
  top: 375px;
}
.text_div1{
  top: 877px;
}
.text_div2{
  top: 1373px;
}
.ba{
  width: calc(20% - 10px); /* Subtracting margin or padding from the width */
  float: left;
}

.left_contents{
background: #FFFFFF;
    box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
    border-radius: 4px;
    width: 197px;
    height: 170px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    gap: 40px;
    /* position: fixed; */
    top: 386px;
    /* left: 108px; */
}
.right_contents{
  width: calc(80% - 10px); /* Subtracting margin or padding from the width */
  float: left;
}
a {
  text-decoration: none;
}

.flex{
  display: flex;
  margin-bottom: 8rem;
}

.font-size{
  font-size: 13.5px;
}

.slide_div {
  width: 312px !important;
  height: 362px;
  background-color: #ffffff;
  border-radius: 5px 5px 4px 4px;
  overflow: hidden;
  transition: all 0.8s;
  margin-right: 0.5rem;
  filter: drop-shadow(1px 3px 20px rgba(1, 1, 1, 0.07));
}
.slide_div:hover {
  transition: all 0.8s;
}
.left_mobile{
  display: contents;
}
.slide_div:hover img {
  transition: all 0.8s;
  transform: scale(1.05);
}
.slide_div .item {
  width: 100%;
  height: 100%;
  transition: 0.8s;
}
.slide_div .img_body {
  width: 100%;
  height: 134px;
  object-fit: cover;
}
.slide_div .img_body img {
  transition: all 0.8s;
}
.slide_info {
  width: 312px;
  height: 228px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px 32px;
  gap: 8px;
  background: #ffffff;
  border-radius: 0 0 4px 4px;
}
.slide_info .time {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #333333;
  margin-top: 0.5rem;
}
.slide_title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1e1b2e;
  align-self: stretch;
}
.slide_title_a {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1e1b2e;
  align-self: stretch;
  transition: all 0.8s;
}
.slide_title_a:hover {
  transition: all 0.8s;
  transform: scale(1.05);
}
.slide_text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
  align-self: stretch;
}
@media only screen and (max-width: 992px){
   .yellow_bg{
    background: #F8D210;
    border-radius: 4px;
    width: 100%;
    height: 188px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 2;
    top: 78px;
}
.main_contents{
  display: flex;
    width: 1200px;
    align-items: center;
    margin: 0 auto;
    gap: 3rem;
    flex-direction: column;
}
.left_mobile{
    width: 100vw;
    height: 64px;
    display: flex;
    background: #FCFCF5;
    box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07) !important;
    position: fixed;
    left: 1px;
    top: 266px;
    padding: 20px;
    gap: 12px;
    z-index: 2;
    overflow-x: auto; 
}
.flex{
  display: flex;
  margin-bottom: 8rem;
  flex-direction: column;
}
.bg_heading{
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 35px;
line-height: 42px;
text-align: center;
width: 343px;
height: 84px;
color: #000000;
}
.left_contents{
  background: #FFFFFF;
    box-shadow: 1px 3px 20px rgba(255, 255, 255, 0.07);
    border-radius: 4px;
    width: 90%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 40px;
    position: relative !important;
    top: -77px !important;
    left: 20px;
}
.text_div {
    top: 412px;
}
.text_div1 {
    top: 1665px;
}
.text_div2 {
    top: 2920px;
}
.slide_div {
    width: 343px !important;
    height: 362px;
    background-color: #ffffff;
    border-radius: 5px 5px 4px 4px;
    overflow: hidden;
    transition: all 0.8s;
    margin-right: 0.5rem;
    margin-bottom: 0.9rem;
}
.ba {
    width: calc(20% - 10px);
    float: left;
    margin: 0 auto;width: 100%;
}
.right_contents {
    width: 60%;
    float: left;
    margin: 0 auto;
}
}
@media only screen and (width: 540px) {
  .right_contents {
    width: 82%;
    float: left;
    margin: 0 auto;
}
}
@media only screen and (max-width: 510px){
   .yellow_bg{
    background: #F8D210;
    border-radius: 4px;
    width: 100%;
    height: 188px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.main_contents{
  display: flex;
    width: 100%;
    align-items: center;
    margin: 0 auto;
    gap: 3rem;
    flex-direction: column;
}
.flex{
  display: flex;
  margin-bottom: 8rem;
  flex-direction: column;
}
.bg_heading{
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 35px;
line-height: 42px;
text-align: center;
width: 343px;
height: 84px;
color: #000000;
}
.left_contents{
  /* background: #FFFFFF;
    box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07); */
    border-radius: 4px;
    width: 90%;
    height: 144px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 40px;
    position: relative !important;
    top: -77px;
    left: 20px;
}
.text_div {
    top: 400px;
}
.text_div1 {
    top: 1664px;
}
.text_div2 {
    top: 2925px;
}
.slide_div {
    width: 343px !important;
    height: 362px;
    background-color: #ffffff;
    border-radius: 5px 5px 4px 4px;
    overflow: hidden;
    transition: all 0.8s;
    margin-right: 0.5rem;
    margin-bottom: 0.9rem;
}
.ba {
    width: calc(20% - 10px);
    float: left;
    margin: 0 auto;width: 100%;
}
.right_contents {
    width: 83%;
    float: left;
    margin: 0 auto;
}
}

@media only screen and (max-width: 400px){
  .yellow_bg{
    background: #F8D210;
    border-radius: 4px;
    width: 100%;
    height: 188px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.main_contents{
  display: flex;
    width: 100%;
    align-items: center;
    margin: 0 auto;
    gap: 3rem;
    flex-direction: column;
}
.flex{
  display: flex;
  margin-bottom: 8rem;
  flex-direction: column;
}
.bg_heading{
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 35px;
line-height: 42px;
text-align: center;
width: 343px;
height: 84px;
color: #000000;
}
.left_contents{
    border-radius: 4px;
    width: 90%;
    height: 139px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 40px;
    position: relative !important;
    top: -77px;
    left: 20px;
}
.text_div {
    top: 393px;
    /* z-index: 99; */
}
.text_div1 {
   top: 1660px;
}
.text_div2 {
   top: 2920px;
}
.slide_div {
    width: 343px !important;
    height: 362px;
    background-color: #ffffff;
    border-radius: 5px 5px 4px 4px;
    overflow: hidden;
    transition: all 0.8s;
    margin-right: 0.5rem;
    margin-bottom: 0.9rem;
}
.ba {
    width: calc(20% - 10px);
    float: left;
    margin: 0 auto;width: 100%;
}
.right_contents {
    width: 90%;
    float: left;
    margin: 0 auto;
}
}

@media only screen and (width: 360px){
  .right_contents {
    width: 94%;
    float: left;
    margin: 0 auto;
}
}