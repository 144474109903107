.modal-shadow {
    overscroll-behavior: unset;
    position: fixed;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    min-height: 100vh;
    width: 100vw;
    z-index: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .blur_background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: 100;
  }

  @media only screen and (max-width: 992px) {
    .modal-shadow {
      padding: 0rem 1rem;
    }
  }