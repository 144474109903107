.section_no_background {
   margin-top: 2rem;
   margin-bottom: 5rem;
}

@media only screen and (max-width: 1200px) {
   .section_no_background {
      width: calc(100vw - 24px);
   }
}

@media only screen and (max-width: 992px) {
   .section_no_background {
      width: 100vw;
   }
}

.as_feature_in {
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   gap: 40px;
}

.as_feature_in_title {
   font-style: normal;
   font-weight: 700;
   font-size: 28px;
   line-height: 36px;
   color: #1e1b2e;
}

.news_item {
   width: 160px;
   height: 118px;
   display: flex;
}

.as_feature_in .news_logo_row {
   background-color: #fff;
   box-shadow: 1px 3px 20px rgb(1 1 1 / 7%);
   border-radius: 4px;
   max-width: 852px;
   height: 198px;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   padding: 40px 70px;
   gap: 24px;
}

.feature_img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}
.as_feature_in .news_logo_row .news_item {
   width: 160px;
   height: 118px;
}

@media only screen and (max-width: 992px) {
   .as_feature_in {
      gap: 36px;
   }
}

@media only screen and (max-width: 992px) {
   .as_feature_in .as_feature_in_title {
      font-size: 20px;
      line-height: 30px;
   }
}

@media only screen and (max-width: 992px) {
   .as_feature_in .news_logo_row {
      background-color: #fcfcf5;
      box-shadow: none;
      border-radius: 4px;
      max-width: 343px;
      height: 257px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 0;
      gap: 21px;
   }
   .feature_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
   }
}

@media only screen and (max-width: 992px) {
   .as_feature_in .news_logo_row .news_item {
      background: #fff;
      border-radius: 4px;
      overflow: hidden;
   }
}
