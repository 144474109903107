.section_no_background{
  display: flex;
  flex-direction: column;
  max-width: 75rem;
  width: 82%;
  gap: 3rem;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
}
.student_success_stories{
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
    margin-bottom: 2rem;
}

.arrowNext{
  font-size: 2rem;
  display: none;
}

.prevArrow{
  font-size: 2rem;
  display: none;
}

.sss_carousel_row{
  width: 100%;
    overflow: hidden;
    position: relative;
    flex-direction: row;
    box-shadow: 1px 3px 20px rgb(1 1 1 / 7%);
    border-radius: 4px;
    height: 500px;
}
.pagination{
   position: absolute;
    margin: 0 auto;
    width: 88%;
    align-items: center;
    cursor: pointer;
    bottom: -30px;
    height: 36px;
  }

.barContainer {
  height: 6px; 
  background-color: #ddd; 
  border-radius: 2px; 
  overflow: hidden; 
  margin: 16px 0; 
  position: relative; 
}

.bar {
  height: 100%; 
  background-color: #000; 
  position: absolute; 
  top: 0;
  left: 0;
  width: 33.33%; 
  transition: width 0.5s ease-in-out; 
}
    .top_stroke {
      display: flex;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none; /* For Firefox */
      -ms-overflow-style: none; /* For Internet Explorer and Edge */
      white-space: nowrap;
      padding-bottom: 10px; /* Add some padding to account for the scrollbar */
      /* border-bottom: 2px solid #D8D8D8; */
      width: 64.75rem;
      /* height: 0px; */
      align-items: center;
      gap: 4rem;
      justify-content: center;
      z-index: 0;
    }

    .top_stroke::-webkit-scrollbar {
      display: none; /* For Chrome, Safari, and Opera */
    }

    .top_stroke>button {
      flex: 0 0 auto;
      margin-right: 10px; /* Add some space between buttons */
      order: 0;
      flex-grow: 0;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #000000;
      box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px 24px;
      width: 83px;
      height: 56px;
      position: relative;
      z-index: 99;
      background-color: #fff;
    }
.top_stroke>button:focus{
  background: #F8D210;
}
.long_stroke{
  border: 1px solid #D8D8D8;
  width: 1200px;
  height: 0px;
  display: flex;
}
.top_margin{
  margin-top: 3.5rem;
  margin-left: 2.5rem;
}
.year_title{
  color: #1E1B2E;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  width: 210px;
  height: 36px;
  margin-bottom: 2rem;
}
.first_desc{
  color: #333333;
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 28px;
width: 400px;
}
.desc_wrapper{
  margin-top: 4rem;
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.content_container{
  display: flex;
  gap: 3rem;
}
.content_right{
  position: relative;
    width: 563px;
    height: 407px;
    bottom: -58px;
}
.content_img{
  width: 563px;
}
.second_desc{
width: 400px;
height: 84px;
color: #333333;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 28px;
}
.flex_btn_left{
  position: absolute;
  left: 0;
}
.flex_btn_right{
  position: absolute;
  right: 0;
}
.active {
  background-color: #f8d210 !important;
}

.bottom_line{
    border-bottom: 2px solid #D8D8D8;
    width: 64.75rem;
    position: absolute;
}


@media only screen and (max-width: 992px){
.top_stroke {
    /* border-bottom: 2px solid #D8D8D8; */
    width: 100%;
    height: 100px;
    display: flex;
    gap: 9px;
    align-items: center;
    justify-content: flex-start;
    z-index: 0;
    /* max-width: 13.1875rem; */
}
.top_stroke>button {
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;
    width: 88px;
    height: 56px;
    position: relative;
    z-index: 99;
    background-color: #fff;
}
.flex_btn_right {
    position: absolute;
    right: 0;
    top: -11px;
}
.flex_btn_left {
    position: absolute;
    left: 0;
    top: -11px;
}
.year_title {
    color: #1E1B2E;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 30px;
    width: 145px;
    height: 30px;
    margin-bottom: 2px;
}
.top_margin {
    margin-top: 1.5rem;
    margin-left: 1.5rem;
}
.student_success_stories {
   width: 114%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
    margin-bottom: 0.3rem;
}
.sss_carousel_row {
      width: 100%;
    overflow: hidden;
    position: relative;
    flex-direction: row;
    box-shadow: 1px 3px 20px rgb(1 1 1 / 7%);
    border-radius: 4px;
    height: 813px;
    padding: 0.3rem;
}
.content_container {
  position: relative;
    margin-left: 0.5rem;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    overflow: hidden;
    align-items: center;
    width: 100%;
}
.desc_wrapper {
    margin-top: 0.3rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}
.first_desc{
      padding: 3px;
    position: relative;
    left: -33px;
    color: #333333;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    width: fit-content;
}
.second_desc {
  padding: 3px;
        position: relative;
    width: fit-content;
    right: 34px;
    height: 84px;
    color: #333333;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}
.content_right {
        position: relative;
    width: 400px;
    height: 334px;
    right: 6px;
    bottom: -39px;
}
.pagination {
   position: absolute;
    margin: 0 auto;
    width: 100%;
    align-items: center;
    cursor: pointer;
    bottom: -36px;
    height: 36px;
}
}

@media only screen and (max-width: 820px){
  .sss_carousel_row {
    width: 100%;
    overflow: hidden;
    position: relative;
    flex-direction: row;
    box-shadow: 1px 3px 20px rgb(1 1 1 / 7%);
    border-radius: 4px;
    height: 869px;
    padding: 0.3rem;
}
.desc_wrapper {
          margin-top: 0.3rem;
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex: 1;
        flex: 1;
        height: 400px;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0 20px 0 37px;
        box-sizing: border-box;
}
.first_desc {
  padding: 3px;
    position: relative;
    left: -33px;
    color: #333333;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    width: 633px;
}
.second_desc {
  padding: 3px;
    position: relative;
    width: 633px;
    right: 34px;
    height: 84px;
    color: #333333;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}
.content_right {
   position: relative;
    width: 540px;
    height: 443px;
    right: -3px;
    bottom: -24px;
}
}

@media only screen and (max-width: 500px){
  .sss_carousel_row {
   width: 100%;
    overflow-x: hidden;
    position: relative;
    /* flex-direction: row; */
    display: flex;
    flex-direction: column;
    box-shadow: 1px 3px 20px rgb(1 1 1 / 7%);
    border-radius: 4px;
    height: 49vh;
    padding: 0.3rem;
    /* overflow-y: auto; */
}
.first_desc {
 padding: 3px;
    position: relative;
    /* left: -16px; */
    color: #333333;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    width: fit-content;
}
.second_desc {
  padding: 3px;
    position: relative;
    width: fit-content;
    /* right: 20px; */
    height: 84px;
    color: #333333;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
}
.content_right {
   position: relative;
    width: 343px;
    height: fit-content;
    right: 1px;
    bottom: 0px;
    flex: 0 0 auto; 
}
}



@media only screen and (max-width: 540px){
  .first_desc {
    padding: 3px;
    position: relative;
    left: -33px;
    color: #333333;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    width: fit-content;
}
.second_desc {
  padding: 3px;
    position: relative;
    width: 447px;
    right: 34px;
    height: 84px;
    color: #333333;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}
.content_right {
    position: relative;
    width: 343px;
    height: 443px;
    right: 11px;
    bottom: 0px;
}
}

@media only screen and (max-width: 390px){
  .sss_carousel_row {
   width: 100%;
    overflow-x: hidden;
    position: relative;
    /* flex-direction: row; */
    display: flex;
    flex-direction: column;
    box-shadow: 1px 3px 20px rgb(1 1 1 / 7%);
    border-radius: 4px;
    height: 53vh;
    padding: 0.3rem;
    /* overflow-y: auto; */
}
.first_desc {
 padding: 3px;
    position: relative;
    /* left: -16px; */
    color: #333333;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    width: fit-content;
}
.second_desc {
  padding: 3px;
    position: relative;
    width: fit-content;
    /* right: 20px; */
    height: 84px;
    color: #333333;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
}
.content_right {
   position: relative;
    width: 343px;
    height: fit-content;
    right: 1px;
    bottom: 0px;
    flex: 0 0 auto; 
}
}

@media only screen and (max-width: 376px){
  .sss_carousel_row {
   width: 100%;
    overflow-x: hidden;
    position: relative;
    /* flex-direction: row; */
    display: flex;
    flex-direction: column;
    box-shadow: 1px 3px 20px rgb(1 1 1 / 7%);
    border-radius: 4px;
    height: 67vh;
    padding: 0.3rem;
    /* overflow-y: auto; */
}
.first_desc {
 padding: 3px;
    position: relative;
    /* left: -16px; */
    color: #333333;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    width: fit-content;
}
.second_desc {
  padding: 3px;
    position: relative;
    width: fit-content;
    /* right: 20px; */
    height: 84px;
    color: #333333;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
}
.content_right {
   position: relative;
    width: 343px;
    height: fit-content;
    right: 11px;
    bottom: 0px;
    flex: 0 0 auto; 
}
}