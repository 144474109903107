.rotate_hover {
  transition: 0.8s;
  cursor: pointer;
  width: 13px;
  height: 14px;
}

 .rotate_hover:hover {
    transition: 0.8s;
    rotate: 180deg;
  }