.stories_list {
  width: 100%;
  position: relative;
  margin: auto;
}

.stories {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.stories_column {
  display: flex;
  flex-direction: column;
  max-width: calc(50% - 24px);
  min-width: calc(50% - 24px);
  gap: 24px;
}

.stories_item {
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.first_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.student_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.watch_btn {
  width: 185px;
  height: 48px;
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px; */
}

.avatar {
  width: 64px;
  height: 64px;
  overflow: hidden;
  border-radius: 500px;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.inf {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.title_and_social {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.title_inf {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.social_icons {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.social_icon {
  width: 24px;
  height: 24px;
  color: black;
  background: none;
  cursor: pointer;
  transition: all 0.8s;
}

.social_icon:hover {
  background: #d8d8d8;
}

.social_icon svg {
  text-decoration: none;
  border: 0;
}

.other_inf {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #333333;
  opacity: 0.8;
}

.gray_dot {
  width: 4px;
  height: 4px;
  background: #d8d8d8;
  border-radius: 100%;
  opacity: 1;
}

.flag {
  width: 30px;
  height: 20px;
  opacity: 1;
}

.flag img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.description_stories {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
}

.see_more {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 80px;
}

.see_more_btn {
  width: 222px;
  height: 46px;
}

.top-text {
  font-size: 12px;
  white-space: nowrap;
}

.arrow-up {
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
  padding: 0.5rem 0rem;
}

.top-modal {
  background-color: #fff;
  width: fit-content;
  padding: 0.5rem;
  border-radius: 10px;
  box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);

  cursor: pointer;
  z-index: 4;

  position: relative;
  top: -100px;
  left: 1200px;
}

@media only screen and (max-width: 1200px) {
  .top-modal {
    left: 90%;
    top: 30px;
  }
}

@media only screen and (max-width: 992px) {
  .stories {
    flex-direction: column;
    gap: 16px;
  }

  .stories_column {
    max-width: 100%;
    min-width: 100%;
    gap: 16px;
  }

  .stories_item {
    padding: 24px 16px;
    margin: 0 16px;
  }

  .first_row {
    flex-direction: column;
    gap: 16px;
  }

  .watch_btn {
    width: 309px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .see_more {
    margin-top: 40px;
  }

  .top-modal {
    left: 80%;
  }
}

@media only screen and (max-width: 550px) {
  .top-modal {
    top: 10px;
    left: 60%;
  }
}

@media only screen and (max-width: 360px) {
  .other_inf {
    font-size: 10px;
  }
}