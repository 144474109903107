.signup_wrapper {
  width: 100vw;
  height: 100%;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }

  .inner_form{
    width: 484px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .title{
    display: flex;
    margin-top: 2rem;
    flex-direction: column;
    gap:1rem;
    align-items: center;
  }

  .title_text{
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    color: #fff;
  }

  .login_form{
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF1A;
    padding: 32px;
    gap:10px;
  }

  @media only screen and (max-width: 900px) {
    .inner_form{
      width: 100vw;
    }
  }
