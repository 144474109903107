.section_no_background {
  width: 1200px;
  position: relative;
  margin: auto;
}

.top_banner {
  background-color: #f8d210;
  background-image: url("../../../src/images/bg/Group720049.png");
  background-repeat: no-repeat;
  background-position: top 44px right 69px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 64px 0 101px;
  margin-top: 120px;
  width: 100%;
  align-items: flex-start;
}

.banner_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 14px;
  margin-top: 64px;
}

.tag {
  padding: 8px 16px;
  width: fit-content;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
}

.banner_text {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 492px;
}

.banner_title {
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 45px;
  color: #000000;
}

.banner_message {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}

.banner_button {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  border: 2px solid #000000;
  transition: all 0.8s;
}

.btn span {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btn span:hover {
  font-weight: 700;
}

.enrol_now {
  background: #000000;
  color: #ffffff;
  width: 133px;
  height: 46px;
}

.banner_photo img {
  margin-top: 10px;
}

.first_course_inf {
  position: absolute;
  width: 100%;
  padding: 0 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  bottom: -50px;
}

.inf_point {
  width: 237.18px;
  height: inherit;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
  border-radius: 4px;
}

.point {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
  align-self: stretch;
}


.info {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1e1b2e;
  align-self: stretch;
  margin-bottom: 4px;
}

.point_description {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #333333;
}
.section_first_background {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 339px;
  margin: auto;
  margin-top: 119px;
  background-color: #fcfcf5;
  background-image: url("../../../src/images/bg/Rec836.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% 330px;
}

.background_section_body {
  width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.card_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    position: relative;
    top: 41px;
}
.card_item {
  width: 295px;
  height: 216px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 8px;
  background: #ffffff;
  box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
  border-radius: 4px;
}
.card_title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1e1b2e;
}

.card_text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}
.img{
  padding-bottom: 9px;
}
@media only screen and (max-width: 1200px) {
  .background_section_body {
    width: calc(100vw - 24px);
  }
}

@media only screen and (max-width: 992px) {
  .section_first_background {
    height: 100%;
    margin-top: 275px;
    background-image: url("../../../src/images/bg/RecMobile836.png");
    background-size: 100% 100%;
    padding-bottom: 40px;
  }
  .card_row {
    flex-direction: column;
    gap: 24px;
    position: relative;
    top: -35px;
  }
  .card_item {
    width: 343px;
    height: 100%;
  }
}


@media only screen and (max-width: 1200px) {
  .section_no_background {
    width: calc(100vw - 24px);
  }
}

@media only screen and (max-width: 992px) {
  .section_no_background {
    width: 100vw;
  }

  .top_banner {
    background-image: none;
    background-color: #f8d210;
    flex-direction: column;
    align-items: center;
    padding: 64px 16px 0 16px;
    margin-top: 20px;
    height: 994px;
  }

  .banner_photo {
    width: 375px;
    overflow: hidden;
    background-image: url("../../../src/images/bg/Group720049.png");
    background-position: bottom center;
    background-repeat: no-repeat;
  }

  .banner_photo img {
    width: 100%;
    height: 100%;
  }

  .banner_info {
    width: 343px;
  }

  .banner_title {
    font-size: 35px;
    line-height: 42px;
  }

  .banner_message {
    font-size: 18px;
    line-height: 27px;
  }

  .banner_button {
    justify-content: space-between;
    flex-direction: column;
  }

  .banner_button button {
    width: fit-content;
  }

  .enrol_now {
    padding: 16px 44px;
  }

  .first_course_inf {
    width: 343px;
    margin-left: calc(50vw - 176px);
    padding: 0;
    bottom: -160px;
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(2, 1fr);
  }

  .inf_point {
    width: 168px;
    height: 100%;
    padding: 16px 24px;
  }
}
