@keyframes slidein_first {
  from {
    bottom: 0;
  }

  to {
    bottom: -800px;
  }
}

@keyframes slidein_second {
  from {
    bottom: 0;
  }

  to {
    bottom: -160px;
  }
}

@keyframes slidein_three {
  from {
    bottom: 0;
  }

  to {
    bottom: -480px;
  }
}

@keyframes slidein_foo {
  from {
    bottom: 0;
  }

  to {
    bottom: -640px;
  }
}

@keyframes slidein_five {
  from {
    bottom: 0;
  }

  to {
    bottom: -1120px;
  }
}

@keyframes slidein_six {
  from {
    bottom: 0;
  }

  to {
    bottom: -480px;
  }
}

@keyframes slidein_seven {
  from {
    bottom: 0;
  }

  to {
    bottom: -1440px;
  }
}

.numbers_block {
  width: 1028px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 62px;
}

.sub_block {
  /* width: calc(50% - 70px); */
  height: 100px;
  display: flex;
  flex-direction: row;
  gap: 100px;
}

.second_item {
  margin-left: 0;
}

.item_block {
 display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.item_text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #333333;
  white-space: nowrap;
}

.number_block {
  display: flex;
  flex-direction: row;
}

.item {
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 80px;
  color: #1e1b2e;
}

.num {
  position: relative;
  overflow: hidden;
  height: 80px;
  width: 53px;
}

.roll_second {
  position: absolute;
  width: 53px;
  height: 880px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  animation-duration: 3s;
  animation-name: slidein_first;
}

.roll_first {
  position: absolute;
  width: 53px;
  height: 240px;
  display: flex;
  flex-direction: column;
  animation-duration: 3s;
  animation-name: slidein_second;
}

.roll_three {
  position: absolute;
  width: 53px;
  height: 560px;
  display: flex;
  flex-direction: column;
  animation-duration: 3s;
  animation-name: slidein_three;
}

.roll_foo {
  position: absolute;
  width: 53px;
  height: 720px;
  display: flex;
  flex-direction: column;
  animation-duration: 3s;
  animation-name: slidein_foo;
}

.roll_five {
  position: absolute;
  width: 53px;
  height: 1200px;
  display: flex;
  flex-direction: column;
  animation-duration: 3s;
  animation-name: slidein_five;
}

.roll_six {
  position: absolute;
  width: 53px;
  height: 560px;
  display: flex;
  flex-direction: column;
  animation-duration: 3s;
  animation-name: slidein_six;
}

.roll_seven {
  position: absolute;
  width: 53px;
  height: 1520px;
  display: flex;
  flex-direction: column;
  animation-duration: 3s;
  animation-name: slidein_seven;
}

@media only screen and (max-width: 992px) {
  .numbers_block {
    width: 343px;
    flex-direction: column;
    height: 224px;
    gap: 24px;
  }
  .sub_block {
    width: 100%;
    justify-content: flex-start;
    gap: 50px;
  }
  .second_item {
    margin-left: 5px;
  }
  .item {
    font-size: 60px;
  }
  .num,
  .roll_first,
  .roll_second,
  .roll_three,
  .roll_foo,
  .roll_five,
  .roll_six,
  .roll_seven {
    width: 37px;
  }
}
