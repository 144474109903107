.section_no_background {
  width: 1200px;
  margin: auto;
  /* display: flex; */

}

@media only screen and (max-width: 1200px) {
  .section_no_background {
    width: calc(100vw - 24px);
  }
}

@media only screen and (max-width: 992px) {
  .section_no_background {
    width: 100vw;
  }
}