.banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: -6;
  width: 1200px;
}

.banner_front {
  display: flex;
  justify-content: left;
  align-items: center;
}

.banner_discount {
  margin-right: 40px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 42px;

  color: #000;
}

.banner_description {
  width: 314px;
}

.banner_description h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000;
}

.banner_description p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #333333;
}

.banner_timer {
  width: 355px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner_timer span {
  display: block;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  text-align: center;

  color: #333333;

  opacity: 0.2;
  margin: 12px;
}

.banner_timer p {
  display: block;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;

  text-align: center;

  color: #333333;

  opacity: 0.6;
}

.banner_timer h1 {
  display: block;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;

  text-align: center;

  color: #1E1B2E;
}

.btn {
  text-align: center;
}

.copy_popup {
  position: fixed;
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 1.5s;
  animation-name: fadeIn;
  animation-duration: 1.5s;
  z-index: 2;
}

.copy_popup img {
  position: absolute;
  left: 70px;
  max-width: none;
  transform: rotate(180deg);
}

.copy_popup_message {
  position: absolute;
  top: 8px;
  left: -54px;
  background: #1E1B2E;

  width: 232px;
  height: 36px;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
}

.copy_popup_message p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #FFFFFF;
  text-align: center;
}

.clickable_banner {
  cursor: pointer;
}

.course_banner {
  position: relative;
}

@media only screen and (max-width: 1200px) {

  .banner {
    width: 100%;
    padding: 0 12px;
  }

  .banner_timer {
    margin-right: 40px;
  }

  .banner_front {
    width: 750px;
  }

  .btn {
    margin-right: 40px;
  }
}

@media only screen and (max-width: 1000px) {
  .btn {
    display: none;
  }

  .banner_timer {
    margin-left: 30px;
    margin-right: 35px;
  }

  .copy_popup {
    position: inherit;
  }

  .copy_popup img {
    top: 50px;
    left: 50%;
  }
  
  .copy_popup_message {
    top: 58px;
    left: 38%;
  }

  .course_banner {
    position: relative;
  }

  .course_banner img {
    top: 30px;
    left: -200px;
  }

  .course_banner .copy_popup_message {
    top: 38px;
    left: -280px;
  }
}

@media only screen and (max-width: 820px) {
  .banner_description p {
    display: none;
  }

  .banner_front {
    display: block;
    margin-left: 10px;
    width: 130px;
  }

  .banner_discount {
    font-weight: 900;
    font-size: 20px;
    line-height: 20px;
    margin-right: 0px;
  }

  .banner_description h1 {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
  }
}

@media only screen and (max-width: 500px) {
  .banner_timer span {
    font-size: 12px;
    line-height: 13px;
    margin: 3.4px;
  }

  .banner_timer p {
    font-size: 10px;
    line-height: 12px;
  }

  .banner_timer h1 {
    font-size: 14px;
    line-height: 14px;
  }

  .banner_timer {
    width: 200px;
    margin-right: 40px;
  }

  .copy_popup img {
    top: 60px;
  }
  
  .copy_popup_message {
    top: 68px;
    left: 25%;
  }

  .expanded_banner {
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between ;
    align-items: center;
  }

  .expanded_section {
    display: flex;
    width: 100%;
  }

  .expanded_btn {
    display: flex;
    margin: 0;
  }

  .expanded_copy_popup img{
    top: 220px;
  }

  .expanded_copy_popup div{
    top: 228px;
  }

  .expanded_banner_description {
    margin-right: 10px;
  }

  .expanded_banner_description p {
    display: block;
  }

  .expanded_banner_front {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .expanded_banner_front h1 {
    display: flex;
    margin-right: 10px;
    text-align: center;

    font-size: 24px;
    line-height: 24px;
  }

  .expanded_banner_description h1 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .expanded_banner_timer span {
    font-size: 16px;
    line-height: 24px;
    margin: 12px;
  }

  .expanded_banner_timer p {
    font-size: 12px;
    line-height: 12px;
  }

  .expanded_banner_timer h1 {
    font-size: 20px;
    line-height: 20px;
  }

  .expanded_banner_timer {
    width: 100%;
  }

  .course_banner img {
    top: 30px;
    left: -200px;
  }

  .course_banner .copy_popup_message {
    top: 38px;
    left: -280px;
  }
}