.card_container {
  display: flex;
  flex-direction: column;
  width: 364px;
  /* height: 314px; */
  background: #ffffff;
  box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
  border-radius: 4px;
  padding: 2rem;
  position: relative;
  /* testing purpose */
  margin: auto;
}

.secondary {
  border: 1px solid #f8d210;
}

.card_title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 1rem;
  padding-top: 0.5rem;
}

.card_amount {
  font-weight: 700;
  font-size: 56px;
  line-height: 60px;
  color: #1e1b2e;
}

.card_content {
  flex-grow: 1;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
  margin-bottom: 1.5rem;
  white-space: pre-line;
}

.pill {
  position: absolute;
  left: 78px;
  top: -18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
  padding: 4px 24px;
  gap: 6px;
  width: fit-content;
  height: 28px;
  background: #f8d210;
  border: 1px solid #f8d210;
  border-radius: 24px;
}

.pill_text {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
}

.card_currency_wrapper {
  display: flex;
  align-items: center;
}

.card_currency {
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  color: #1e1b2e;
  margin-top: 1rem;
}

.card_button {
  margin-top: auto;
  align-self: center;
  width: 242px;
  height: 46px;
}

.installment_text {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #333333;
  opacity: 0.8;
  margin-top: 1.5rem;
  margin-left: 0.2rem;
}

.price_slash {
  position: relative;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  color: #333333;
  opacity: 0.5;
  margin-bottom: 1.5rem;
  margin-left: 0.2rem;
}

.price_slash_currency {
      font-size: 16px;
    position: relative;
    left: 5px;
}

.price_slash::before,
.price_slash::after {
  content: "";
  position: absolute;
  bottom: 50%;
  width: 100%;
  height: 2px;
  background-color: #333333;
}