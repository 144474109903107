.modal-shadow {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 4rem 10rem;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.39);
  z-index: 1400;
   display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  overflow-y: scroll;
  overflow-x: clip;
}

.inner {
  width:1200px;
  background: black;
  color: #fff;
  padding: 0.5rem;
  bottom: 21px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
}

.success-inner{
  max-width: 1200px;
  background: black;
  color: #fff;
  padding: 2rem;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
}

.top_btn {
  float: right;
}

.modal_title_wrapper {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal_title_wrapper2 {
  margin-top: 5rem !important;
}

.modal_title {
  width: 876px;
  margin: auto;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  color: #fcfcf5;
}
.modal_title2 {
  width: 969px;
}

.modal_title3{
  width: 850px;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  color: #fcfcf5;
}

.modal_title3 span {
  color: #f8d210;
  margin-right: 0.5rem;
}

.modal_title span {
  color: #f8d210;
  margin-right: 0.5rem;
}

.modal_subTitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #d8d8d8;
  margin-top: 1rem;
}

.modal_subTitle2 {
  width: 630px;
  margin: auto;
  margin-top: 1rem;
}

.modal_subTitle3{
  width: 548px;
  /* margin: auto;  */
  margin-top: 1rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #D8D8D8;  
}

.contact-wrapper {
  border-radius: 4px;
 background: rgba(255, 255, 255, 0.05) !important;
}

.modal_button_wrapper, .refer_button_wrapper{
  margin-top: 5rem;
  background:rgba(202, 198, 198, 0.15);
  width: 548px;
  height: 136px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0;
}

.refer_button_wrapper p,
.modal_button_wrapper p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #fcfcf5;
  margin-top: 1rem;
}

.modal_button {
  width: 87%;
  height: 48px;
  margin: auto;
  /* margin-top: 1rem; */
}

.modal_icon_wrapper {
  display: flex;
  justify-content: space-between;
  width: 380px;
  margin: 4rem auto 2rem auto;
}

@media only screen and (max-width: 1200px) {
  .inner{
    width: 1000px;
  }
}

@media only screen and (max-width: 990px)
{
  .inner, .success-inner{
    width: calc(100vw - 8px);
    margin-top: 10rem;
  }
  
  .modal_title3{
    width: 750px;
  }
  .modal_title2{
    width: 750px;
  }
}

@media only screen and (max-width: 750px) {
  .modal-shadow{
    overflow-y: scroll;
    overflow-x: clip;
  }
  
  .inner, .success-inner{
    width: calc(100vw - 8px);
    margin-top: 10rem;
    padding: 0.5rem;
  }
  
  .modal_icon_wrapper {
    width: 198px;
    flex-wrap: wrap;
    padding-left: 0.6rem;
  }
  .modal_icon_wrapper img {
    margin-right: 2rem;
    margin-bottom: 1rem;
  }

  .modal_title {
    width: 100%;
    font-size: 20px;
    line-height: 30px;
  }

  .modal_title3 {
    width: 311px;
    font-size: 20px;
    line-height: 30px;
    margin-top: -20px;
  }
  .modal_title2 {
    width: 311px;
    font-size: 20px;
    line-height: 30px;
    margin-top: -20px;
  }
  .modal_title3 {
    width: 311px;
    font-size: 20px;
    line-height: 30px;
    margin-top: -20px;
  }
  
  .modal_button_wrapper, .refer_button_wrapper{
    height: 160px;
    width: 95%;
  }
  .modal_button_wrapper p, .refer_button_wrapper p {
    margin: auto;
    width: 263px;
  }

  .modal_subTitle3{
    width: 100%;
  }
  .modal_subTitle2{
    width: 100%;
  }
}
