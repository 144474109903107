/* Default styles for larger screens */
.main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 104px;
  justify-content: center;
  height: 100vh;
}

.top_layout {
  display: flex;
  width: 640px;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.10);
  box-shadow: 1px 3px 20px 0px rgba(1, 1, 1, 0.07);
  margin: 0 auto;
}

.header {
  width: 370px;
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}

.sub_layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.sub_heading {
  align-self: stretch;
  color: #FCFCF5;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.email_address {
  color: #FCFCF5;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.top_text {
  align-self: stretch;
  color: #D8D8D8;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.sub_text {
  width: 560px;
  color: #D8D8D8;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.bottom_frame {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.bottom_link {
  color: #D8D8D8;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
}

@media (max-width: 990px) {
  .main_container {
    gap: 24px; 
  }

  .top_layout {
    width: 100%;
  }

  .header {
    width: 100%;
  }

  .sub_text {
    width: 96%;
  }
  .outline{
    width: fit-content;
  }
}
