.bg {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  color: #000;
  width: 1200px;
  gap: 40px;
}

.heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;

  color: #1E1B2E;
}

.main_block {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.left_block {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  height: auto;
  background: #fff;
  box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
  border-radius: 4px;
}

.left_block Button {
  display: flex;
  align-items: center;
}

.left_block_img {
  margin-bottom: 40px;
  width: 350px;
}

.left_block h1 {
  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 16px;
}

.left_block Button {
  margin-bottom: 24px;
}

.right_block {
  height: auto;
  flex-shrink: 1;
  background: #fff;
  box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
  border-radius: 4px;
  padding: 25px;
}

.name_block {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  align-items: center;
}

.name_block a {
  margin-right: 15px;
  opacity: 60%;
}

.positions_row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: 8px;
}

.positions_row .position {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 8px;
  background: rgba(0, 0, 0, .05);
  border-radius: 4px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #000;
}

.name_block h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  color: #1E1B2E;
  margin-right: 16px;
}

.description {
  display: flex;
  flex-direction: column;
}

.description p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: #333333;

  margin-bottom: 8px;
}

.description h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: #333333;
  margin-bottom: 20px;
}

.logos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: 20px;
}

.logos img {
  filter: grayscale(100%);
}

@media only screen and (max-width: 1200px) {
  .bg {
    width: 100%;
  }

  .left_block {
    margin-left: 16px;
  }

  .right_block {
    margin-right: 16px;
  }
}

@media only screen and (max-width: 993px) {
  .main_block {
    flex-direction: column;
  }

  .left_block {
    width: auto;
    margin-right: 16px;
  }

  .right_block {
    width: auto;
    margin-left: 16px;
  }
}