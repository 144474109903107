.cardtext__dot {
    height: 4px;
    width: 4px;
    opacity: 100%;
}

.read__more {
    height: 38px !important;
    width: 133.5px !important;
}

@media only screen and (max-width: 1200px) {
    
 }
 
 @media only screen and (max-width: 992px) {
    .read__more {
        width: fit-content !important;
    }
 }