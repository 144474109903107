.page_body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 574px);
  gap: 80px;
  background-color: #fcfcf5;
  overflow: hidden;
}

.page_with_banner {
  position: relative;
  top: 48px;
}

.page_with_banner_timer {
  top: 60px;
}

@media only screen and (max-width: 992px) {
  .page_body {
    gap: 64px;
  }
}

@media only screen and (max-width: 500px){
  .page_with_banner {
    top: 76px;
  }
  
  .page_with_expanded_banner_timer {
    top: 230px;
  }
}
