
.section_background_body {
   width: 1200px;
   display: flex;
   flex-direction: column;
   gap: 100px;
}

@media only screen and (max-width: 1200px) {
   .section_background_body {
      width: calc(100vw - 24px);
   }
}

@media only screen and (max-width: 992px) {
   .section_background_body {
      width: 100vw;
      gap: 40px;
   }
}

.you_should_row {
   display: flex;
   flex-direction: row;
   width: 100%;
   justify-content: space-between;
   gap: 16px;
   padding-top: 60px;
}

.card_block {
   display: flex;
   flex-direction: row;
   gap: 24px;
   width: 60%;
   max-width: 584px;
}

.card_column {
   display: flex;
   flex-direction: column;
   gap: 24px;
   width: 50%;
}

.card_right {
   padding-bottom: 40px;
   width: 100%;
}

.card_left {
   padding-top: 40px;
   width: 100%;
}

.card {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   padding: 32px;
   gap: 8px;
   width: 100%;
   background: #ffffff;
   box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
   border-radius: 4px;
}

.card_icon {
   width: 40px;
   height: 40px;
   color: #f8d210;
}

.card_title {
   font-style: normal;
   font-weight: 700;
   font-size: 16px;
   line-height: 24px;
   color: #1e1b2e;
   align-self: stretch;
}

.description_block {
   width: 431px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: 8px;
}

.description_title {
   max-width: 321px;
   font-style: normal;
   font-weight: 700;
   font-size: 28px;
   line-height: 36px;
   color: #1e1b2e;
}

.description_text {
   max-width: 431px;
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 28px;
   color: #333333;
}

.play_button {
   display: flex;
   flex-direction: row;
   align-items: center;
   padding: 12px 32px 12px 24px;
   gap: 16px;
   border: 2px solid #000000;
   border-radius: 4px;
   width: 185px;
   background-color: #ffffff;

   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   line-height: 14px;
   color: #000000;
}

.play_button:hover {
   font-weight: 700;
}

.play_button span {
   display: flex;
   align-items: center;
   flex-direction: row;
   gap: 16px;
   transition: all 0.8s;
   transform: scale(1);
}

.play_button span:hover {
   transition: all 0.8s;
   transform: scale(1.05);
   font-weight: 700;
}

.description_buttons {
   display: flex;
   flex-direction: row;
   gap: 8px;
}

.arrow_btn {
   background: none;
   border: none;
   display: flex;
   flex-direction: row;
   align-items: center;
   color: black;
}

.description_courses_block {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 8px;
   margin-bottom: 40px;
}

.description_courses_title {
   width: 100%;
   text-align: center;
   font-style: normal;
   font-weight: 700;
   font-size: 28px;
   line-height: 36px;
   color: #1e1b2e;
}

.description_courses_text {
   width: 100%;
   text-align: center;
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 28px;
   color: #333333;
}

.slider_block {
   height: 462px;
   width: 100%;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   position: relative;
}

@media only screen and (max-width: 992px) {
   .you_should_row {
      display: flex;
      flex-direction: column;
      flex-flow: column-reverse;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      gap: 24px;
   }

   .description_block {
      width: 343px;
   }
   .description_title {
      font-size: 20px;
      line-height: 30px;
   }
   .description_buttons {
      display: none;
   }

   .card_block {
      gap: 16px;
      width: 343px;
      max-width: 375px;
      display: block;
   }

   .card_column {
      gap: 16px;
   }

   .card_right {
      padding-bottom: 16px;
      width: 100%;
   }

   .card_left {
      padding-top: 16px;
      width: 100%;
   }

   .card_title {
      font-size: 14px;
      line-height: 24px;
   }

   .description_courses_block {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
      margin-bottom: 32px;
   }

   .description_courses_title {
      width: 340px;
      text-align: start;
      font-size: 20px;
      line-height: 30px;
   }

   .description_courses_text {
      width: 340px;
      text-align: start;
   }
}
