.section_no_background {
  width: 1200px;
  position: relative;
  margin: auto;
}
.top_banner {
  background-color: #f8d210;
  background-image: url("../../../images/bar-chart-web.png"),
    url("../../../images/pie-chart-web.png"),
    url("../../../images/pattern-lock-web.png");
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
  background-position: top 61px left 65%, top 81px right 35px,
    top 338px left 58%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 64px 0 101px;
  margin: 120px 0px;
  width: 100%;
  height: 500px;
}

.banner_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
}

.tag {
  padding: 8px 16px;
  width: fit-content;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
}

.banner_text {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 492px;
}
.banner_title {
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  color: #000000;
}

.banner_message {
  width: 523px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}

.banner_message span {
  font-weight: 600;
}

.banner_photo {
  overflow: hidden;
  position: absolute;
  right: 0;
}

.banner_photo img {
}

@media only screen and (max-width: 1200px) {
  .section_no_background {
    width: calc(100vw - 24px);
  }
  .top_banner {
    background-position: top 61px left 57%, top 81px right 35px,
      top 338px left 52%;
  }
  .banner_message {
    width: 350px;
  }
}

@media only screen and (max-width: 992px) {
  .section_no_background {
    width: 100vw;
  }
  .top_banner {
    background-image: none;

    flex-direction: column;
    align-items: center;
    padding: 64px 16px 0 16px;
    margin-top: 70px;
    margin-bottom: 70px;
    height: 793px;
  }

  .banner_photo {
    width: 375px;
    background-image: url("../../../images/bar-chart-mobile.png"),
      url("../../../images/pie-chart-mobile.png"),
      url("../../../images/pattern-lock-mobile.png");
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-position: top 31px left 23%, top 31px right 0px, top 170px left 11%;
    position: unset;
    right: 0;
    margin-right: 2rem;
  }
  .banner_photo img {
    width: 100%;
    height: 100%;
  }

  .banner_info {
    width: 343px;
  }

  .banner_title {
    font-size: 35px;
    line-height: 42px;
  }

  .banner_message {
    width: 342px;
    font-size: 14px;
    line-height: 24px;
  }
}
