.section_second_background {
  width: 100%;
  min-height: 584px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  background-color: #fcfcf5;
  background-image: url("../../../images/bg/rectangle870.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% 584px;
}
.background_section_body {
  width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.price_row_first {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
}

.price_check {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 40px;
  gap: 5px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  width: fit-content;
}
.first_row_price {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #1e1b2e;
  width: 100%;
  text-align: start;
}

.second_row_price {
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  color: #1e1b2e;
  width: 100%;
  text-align: center;
}

.last_row_price {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #333333;
  opacity: 0.8;
  width: 100%;
  text-align: end;
}

.description_check {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}
.title_description_check {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: #1e1b2e;
}

.note {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #1e1b2e;
}
.card_var_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 64px;
}
.card_var {
  width: 560px;
  min-height: 432px;
  padding: 40px;
  background: #ffffff;
  box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
  position: relative;
}
.flag {
  position: absolute;
  padding-top: 8px;
  left: -4px;
  top: 16px;
  width: 122px;
  height: 40px;
  background-image: url("../../../images/bg/Flag.png");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 122px 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  text-align: center;
}

.best_choice_title {
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #1e1b2e;
}

.best_choice_description {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.description_text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #333333;
}

.promo_cod_block {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  gap: 25px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.promo_btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
}
.line_vertical {
  height: 24px;
  border: 1px solid #1e1b2e;
}

.copy_btn {
  background: none;
  border: none;
  padding: 0;
}

.promo_text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.inf_add {
  position: relative;
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 3s;
  animation-name: fadeIn;
  animation-duration: 3s;
}
.inf_add img {
  position: absolute;
  top: -32px;
  left: -15px;
}

.inf_add div {
  top: -67px;
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 10px;
  background: #1e1b2e;
  height: 36px;
}

.add_info {
  width: 188px;
  left: -94px;
}

.order_btn {
  width: 100%;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 16px;
  background: #000000;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
}
.order_btn span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.order_btn span:hover {
  font-weight: 700;
}

.standard_enrolment {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 22px;
}
.row_standard_enrolment {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 21px;
}

.point_standard {
  font-style: normal;
  font-weight: 900;
  font-size: 60px;
  line-height: 60px;
}
.first_span {
  color: #ffffff;
  -webkit-text-stroke: 2px #f8d210;
}

.text_standard_enrolment {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #333333;
}
.gold_border {
  border: 2px solid #f8d210;
}
@media only screen and (max-width: 1200px) {
.background_section_body {
    width: calc(100vw - 24px);
}
}
@media only screen and (max-width: 992px) {
  .section_second_background {
    min-height: 1400px;
    background-size: 100% 90%;
  }
  .title_description_check {
    font-size: 20px;
    line-height: 30px;
  }

  .card_var {
    width: 343px;
    padding: 24px;
  }
  .card_var_row {
    flex-direction: column;
    gap: 24px;
  }
  .row_standard_enrolment {
    flex-direction: column;
    gap: 4px;
  }
  .point_standard {
    font-size: 40px;
    line-height: 40px;
  }
  .price_row_first {
    flex-direction: column;
    flex-flow: column-reverse;
    align-items: flex-start;
    gap: 16px;
  }
  .gold_border {
    padding: 56px 24px 24px 24px !important;
  }
}
