.cursor_pointer {
  cursor: pointer;
}

.filter_blur {
  -webkit-filter: blur(10px);
  filter: blur(10px);
}

.main {
  z-index: 30;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #000000;
}

.auth_bar{
  background: #333;
}

.auth{
  height: 40px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 40px;
  color: var(--primary-white, #FFF);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  /* 100% */
}

.auth div {
  display: flex;
  align-items: flex-end;
  gap: 4px;
  cursor: pointer;
}

.header_body {
  width: 1200px;
  height: 78px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 54px;
  background: #000000;
}

.logo {
  width: 100px;
  height: 44px;
  cursor: pointer;
}

.nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  /* gap: 62px; */
}

.menu_items {
  margin-right: 62px;
}

.menu_items section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
}

.mobile_menu {
  display: none;
}

.button_parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}

.nav_title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  background: none;
  box-shadow: none;
  border: none;
}

.nav_title:hover {
  /* font-weight: 800; */
  color: #f8d210;
}

.buttons_block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.buttons_block button {
  /* padding: 16px 32px; */
  text-align: center;
  border: none;
  border-radius: 4px;
}

.btn {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.arrow_icon {
  margin-left: auto;
}

.sign_up {
  background: none;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  width: 112px;
  height: 46px;
}

.sign_up:hover {
  font-weight: 800;
  color: #f8d210;
}

.get_started {
  color: black;
  text-transform: capitalize;
  background: #f8d210;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  width: 145px;
  height: 46px;
}

.get_started span:hover {
  font-weight: 700;
}

.active {
  color: #f8d210;
}

.menu_icon {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .header_body {
    width: 100%;
    padding: 0 12px;
  }

  .auth_bar {
    padding: 0 16px;
  }

  .auth {
    gap: 24px
  }

  .auth div {
    padding: 12px 8px;
  }
}

@media only screen and (max-width: 992px) {
  .menu_icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    color: #ffffff;
    width: 48px;
    height: 48px;
  }

  .menu_icon svg {
    width: 100%;
    height: 100%;
  }

  .nav {
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: space-between;
    background: black;
    max-width: 316px;
    width: 316px;
    padding: 0 0 0 0;
    top: 0;
    right: 0;
    z-index: 30;
    height: 100%;
  }

  .long_nav {
    overflow-y: auto;
  }

  .back_filter {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    backdrop-filter: blur(12px);
    background: rgba(0, 0, 0, 0.16);
    width: calc(100vw - 316px);
    min-height: 100vh;
    height: 100%;
    z-index: 30;
  }

  .back_arrow {
    border: none;
    background: none;
    color: #ffffff;
  }

  .menu_items {
    flex-direction: column;
    width: 100%;
    gap: 0;
    margin-right: 0px;
  }

  .menu_items section {
    flex-direction: column;
    gap: 0;
  }

  .menu_items section>* {
    width: 100%;
    height: 72px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }

  .nav_title {
    display: flex;
    width: 100%;
  }

  .button_parent span {
    display: block;
    text-align: left;
    width: 100%;
  }

  .button_parent img {
    rotate: -90deg;
  }

  .buttons_block {
    margin-top: 6px;
    flex-flow: row-reverse;
    padding: 16px 16px 40px 16px;
  }

  .buttons_block>* {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    /* 100% */
  }

  .sign_up {
    padding: 0px !important;
  }

  .mobile_menu {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 72px;
    color: #ffffff;
    padding: 0px 16px;
  }

  .selected_name {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  .parent_position {
    position: relative;
  }
}

@media only screen and (max-width: 500px) {
  .banner_with_header {
    top: 76px;
  }

  .banner_with_expanded_header {
    top: 230px;
  }
}