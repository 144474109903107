.container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:32px;
    padding: 20px;
    width: 484px;
    background: #000000;
    border-radius: 4px;
    color: #FFFFFF;
}

.heading1, .heading2{
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.heading2{
    padding: 1rem 0;
}
/* 
@media only screen and (max-width: 750px){
    .container{
        width: 343px;
        margin: 1rem ;
    }
} */

@media only screen and (max-width: 950px){
    .container{
        width: 95%;
        margin: 1rem auto;
    }
}