.btnContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  width: 145px;
  height: 46px;
  cursor: pointer;
}

.btnContainer span {
  display: flex;
  align-items: center;
  gap: 16px;
}

.btnContainer:hover {
  font-weight: 700;
}

.btnContainer:disabled {
  cursor: not-allowed;
}

.btnPrimary {
  background: #000000;
  color: #ffffff;
}

.btnSecondary {
  background: #F8D210;
  color: #000000;
}

.btnWhite {
  background: #ffffff;
  color: #000000; 
}

.btnBlack {
  background: #000000;
  color: white; 
}

.btnDisabled {
  background-color: #D8D8D8;
  color: #333333; 
  cursor: not-allowed;
}

.blackOutline {
  border: 2px solid #000000;
}

.whiteOutline {
  border: 2px solid #ffffff;
}

.yellowOutline {
  border: 2px solid #F8D210;
  color: #F8D210;
}

.btnFull {
  width: 100%;
}

.video {
  flex-direction: row-reverse;
}

.arrow {
  flex-direction: row;
}

.arrow img {
  transform: translateX(5px);
}

@media only screen and (max-width: 992px) {
  .btnContainer {
     width: 100%;
     padding: 16px 16px;
  }
}