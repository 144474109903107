.section_no_background {
  width: 1200px;
  position: relative;
  margin: auto;
}
.what_block_body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.parent_card_column {
  width: 584px;
  display: flex;
  flex-direction: row;
  gap: 24px;
}
.column_block {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.right {
  margin-top: 40px;
}

.card_column_item {
  width: 280px;
  height: fit-content;
  background: #ffffff;
  box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 8px;
}

.column_item_title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1e1b2e;
}

.what_text_parent {
  max-width: 431px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

.what_title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: #1e1b2e;
}

.what_text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #333333;
}

.enrol_btn {
  transition: 0.8s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  border: 2px solid #000000;
  background: #000000;
  color: #ffffff;
  width: 133px;
  height: 46px;
}
.enrol_btn span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.enrol_btn span:hover {
  font-weight: 700;
}

.bonus_tag span {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
}

.tag {
  padding: 8px 16px;
  width: fit-content;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
}

@media only screen and (max-width: 1200px) {
  .section_no_background {
    width: calc(100vw - 24px);
  }
}

@media only screen and (max-width: 992px) {
  .section_no_background {
    width: 100vw;
  }
  .what_block_body {
    flex-direction: column;
    flex-flow: column-reverse;
    gap: 24px;
    align-items: center;
  }
  .parent_card_column {
    width: 342px;
    gap: 16px;
  }
  .column_block {
    gap: 16px;
  }
  .card_column_item {
    width: 163px;
    min-height: 184px;
    padding: 32px;
    gap: 8px;
  }
  .what_text_parent {
    max-width: 341px;
  }

  .what_title {
    font-size: 20px;
    line-height: 30px;
  }
}
