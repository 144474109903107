.google-rating {
  position: fixed;
  left: 10px;
  bottom: 20px;
  display: flex;
  align-items: flex-end;
  background-color: #fff;
  width: 72px;
  height: 36px;
  padding: 10px;
  box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  z-index: 10;
}
.google-rating span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #333333;
  margin-left: 0.3rem;
}
/* .google-rating:hover {
  width: 168px;
  height: 36px;
  justify-content: space-between;
} */

.hover {
  width: 168px;
  height: 36px;
  justify-content: space-between;
}

.hover span {
  margin-left: 0rem;
}

