/* styles.css */

.spinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Adjust as needed */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #F8D210;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
