.section_no_background {
  width: 100%;
  height: 96px;
  background-color: #000000;
  border-bottom: 1px solid #333333;
}

.footer_banner {
  background-image: url("../../images/bg/upTelegramArrow.png"),
    url("../../images/bg/officeMessage.png"),
    url("../../images/bg/officeMessage.png"),
    url("../../images/bg/downTelegramArrow.png");

  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-position: top 1px left 250px, bottom 10px left 60px,
    top 0px right 197px, bottom 10px right 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.cta_wrapper {
  display: flex;
  justify-content: center;
  padding: 30px 0;
  align-items: center;
  margin: auto;
}

.cta_wrapper p {
  font-weight: 700;
  line-height: 24px;
  color: #ffffff;
}

.cta_button {
  width: 263px !important;
  height: 48px !important;
  margin-left: 3rem;
}

@media only screen and (max-width: 992px) {
  .section_no_background {
    height: 168px;
  }
  .footer_banner {
    background-image: url("../../images/bg/mobileTelegramUp.png"),
      url("../../images/bg/mobileDownOffice.png"),
      url("../../images/bg/mobileUpOffice.png"),
      url("../../images/bg/mobileTelegramDown.png");
    background-position: top 0px left 0px, bottom 0px left 0px,
      top 0px right 0px, bottom 10px right 0px;
    padding: 1rem;
  }
  .cta_wrapper {
    flex-direction: column;
  }
  .cta_button {
    margin-left: 0rem;
    margin-top: 1rem;
  }
}
