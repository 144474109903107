@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Poppins", sans-serif;
  background-color: #e5e5e5;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.slick-slider {
  overflow: hidden;
}

.slick-dots li {
  margin: 0px !important;
  width: 100% !important;
  height: 17px !important;
  background-color: #ddd;
}

.slick-list {
  padding-bottom: 1.5rem !important;
}

.slick-active {
  background-color: black !important;
}

.slick-slide {
  background-color: transparent !important;
}

.calendly-overlay {
    background-color: #f7f3f3 !important;
    backdrop-filter: blur(70px);
}

@media only screen and (max-width: 992px) {
  .slick-list {
    padding-bottom: 1rem !important;
  }

  .slick-dots {
    position: unset !important;
    margin: auto !important;
    width: 70% !important;
    left: 15px !important;
    right: 10px !important;
    margin-top: .5rem !important;
    padding: 13px !important;
  }
  
  .slick-dots li {
    height: 6px !important;
    width: 33px !important;
  }
}

/* Hide all spinner elements within the Calendly widget */
span[style*="border-radius: 100%"],
span[style*="react-spinners-MoonLoader-moon"] {
  display: none !important;
}



/* Or alternatively, you can also hide the background and use a pseudo-element */
.calendly-overlay .calendly-popup-close {
    background: none !important; /* Remove the default background */
    width: 19px !important;
    height: 19px !important;
    position: relative;
}

/* Create a custom close icon with pseudo-element */
.calendly-overlay .calendly-popup-close::before {
    content: "×"; /* Use a custom close symbol or Unicode */
    color: #000000; /* Customize color */
    font-size: 44px; /* Adjust size */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    line-height: 19px; /* Center vertically */
}

