.section_no_background {
  width: 1200px;
  position: relative;
  margin: auto;
}
.top_banner {
  background-color: #f8d210;
  background-image: url("../../images/bg/desing-web.png"),
    url("../../images/bg/coding-web.png");

  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-position: top 30px right 400px, bottom 150px right 30px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 64px 0 101px;
  margin: 120px 0px;
  width: 100%;
}

.banner_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
}

.banner_text {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 492px;
}
.banner_title {
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  color: #000000;
}

.banner_message {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.banner_form {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 16px;
}

.notify {
  width: 130px;
  height: 46px;
}

.banner_photo  {
overflow: hidden;
}

.banner_photo img {
  margin-bottom: -5px;
}

.email_input {
  display: flex;
  flex-direction: column;
}

.email_input label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #333333;
}

.email_input input {
  width: 293px;
  height: 44px;
  padding: 16px;
  border: 2px solid #000000;
  border-radius: 4px;
  background: none;
  color: #000000;
}

.email_input input::placeholder {
  color: #333333;
}

@media only screen and (max-width: 1200px) {
  .section_no_background {
    width: calc(100vw - 24px);
  }
  .top_banner {
    background-position: top 10px right 310px, bottom 150px right 30px;
  }
}

@media only screen and (max-width: 992px) {
  .section_no_background {
    width: 100vw;
  }
  .top_banner {
    background-image: none;

    flex-direction: column;
    align-items: center;
    padding: 64px 16px 0 16px;
    margin-top: 70px;
    margin-bottom: 0px;
  }

  .banner_photo {
    width: 375px;
    background-image: url("../../images/bg/design-mobile.png"),
      url("../../images/bg/coding-mobile.png");

    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
    background-position: bottom 250px left 30px, bottom 110px right 10px;
  }
  .banner_photo img {
    width: 100%;
    height: 100%;
  }

  .banner_info {
    width: 343px;
  }

  .banner_title {
    font-size: 35px;
    line-height: 42px;
  }

  .banner_message {
    font-size: 14px;
    line-height: 24px;
  }

  .banner_form {
    justify-content: space-between;
    flex-direction: column;
  }
  .email_input,
  .email_input input {
    width: 100%;
  }
  .notify {
    width: 100%;
    padding: 16px;
  }
}
