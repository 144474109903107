.section_no_background {
  width: 1200px;
  position: relative;
  margin: auto;
}

.top_banner {
  background-color: #f8d210;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 64px 0 101px;
  margin: 120px 0px;
  width: 100%;
  padding-top: 64px;
  padding-bottom: 64px;
}

.banner_info h2 {
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  color: #000000;
  margin-bottom: 20px;
  padding-top: 80px;
}

.banner_info__subtitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  padding-bottom: 80px;
  width: 370px;
}

.contact_info {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.contact_info p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #1e1b2e;
  margin-left: 10px;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 16px;
  width: 484px;
  height: 530px;
  background: #000000;
  box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
  border-radius: 4px;
}

.form {
  width: 484px;
  box-shadow: 1px 3px 20px rgb(1 1 1 / 7%);
  border-radius: 4px;
  background-color: black;
  padding: 20px;
  padding-bottom: 30px;
}

.input {
  width: 100%;
  height: 44px;
  padding: 16px;
  border: 2px solid #ffffff;
  border-radius: 4px;
  background-color: black;
  color: #ffffff;
  font-size: 12px;
  outline: none;
}

.inputWrapper {
  display: flex;
  margin-bottom: 1.25rem;
}

.label {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #fcfcf5;
}

.textArea {
  padding: 16px;
  width: 420px;
  height: 144px;
  border: 2px solid #ffffff;
  border-radius: 4px;
  font-size: 12px;
}

.textAreaContainer {
  margin-top: -45px;
}

.selectContainer {
  margin-top: -45px;
  margin-bottom: 1.5rem;
}

.selectWrapper {
  width: 100%;
  height: 44px;
  padding: 10px;
  border: 2px solid #ffffff;
  border-radius: 4px;
  background-color: black;
  color: #ffffff;
  padding-right: 0px;
}

.select {
  width: 95%;
  color: #d8d8d8;
  font-size: 12px;
  outline: none;
}

.textArea {
  position: relative !important;
  z-index: auto !important;
  width: 100%;
  height: 144px;
  background-color: black;
  border: 2px solid #ffffff;
  color: white;
  outline: medium;
}

.textArea:focus { 
  outline: none !important;
  border-color: 5px solid #f8d210;
  border: 1px solid #f8d210;
  box-shadow: 0 0 0 0.125rem #f8d210;
}

.socials {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.socials img {
  cursor: pointer;
}

.contry_dropdown {
  width: 98%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: relative;
  bottom: 50px;
  right: -11px;
  color: #ffffff;
  font-size: 12px;
}

.contry_flag {
  position: relative;
  width: 14%;
  height: 44px;
  padding: 16px;
  border: 2px solid #ffffff;
  border-radius: 4px;
  background-color: black;
  color: #ffffff;
  bottom: -10px;
  left: -4px;
}

@media only screen and (max-width: 1200px) {
  .section_no_background {
    width: calc(100vw - 24px);
  }

  .socials {
    width: 300px;
  }
}

@media only screen and (max-width: 992px) {
  .section_no_background {
    width: 100vw;
  }

  .top_banner {
    flex-direction: column;
    align-items: center;
    padding: 64px 16px 0 16px;
    margin-top: 70px;
    margin-bottom: 0px;
  }

  .socials {
    width: 300px;
    margin-top: 70px;
    position: absolute;
    bottom: 90px;
    padding: 0px 15px;
    padding-right: 30px;
  }

  .contactWrapper {
    position: absolute;
    bottom: 150px;
    padding-left: 15px;
  }

  .banner_info {
    width: 100%;
  }

  .banner_info h2 {
    padding-top: 0px;
    margin-bottom: 10px;
    font-size: 40px;
  }

  .banner_info__subtitle {
    padding-bottom: 30px;
    font-size: 18px;
    width: 100%;
  }

  .form {
    width: 100%;
    margin-bottom: 300px;
    padding-bottom: 40px;
  }

  .inputWrapper {
    flex-direction: column;
  }

  .contry_flag {
    align-items: center;
    background-color: #000;
    bottom: -9px;
    display: flex;
    justify-content: center;
    left: -1px;
    padding: 17px;
    position: relative;
    width: 18%;
    border: 2px solid #fff;
    border-radius: 4px;
    color: #fff;
    height: 44px;
  }

  .contry_dropdown {
    position: relative;
    bottom: 51px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    right: -8px;
  }
}