.section_full_black_background {
  width: 100%;
  background: #000000;
  padding: 104px 0 104px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section_no_background {
  width: 1200px;
}
.video_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0;
}

.play_block {
  width: 389px;
  height: 260px;
  position: relative;
}


.video {
  width: 389px;
  height: 260px;
  display: block;
}
.controls {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.controls:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}

.video-control {
  transition: opacity 200ms linear;
}

.control-shown {
  opacity: 1;
}

.control-hidden {
  opacity: 0;
}

.video-content {
  position: absolute;
  width: 100%;
  bottom: -43px;
  padding: 0rem 1rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 70.67%);
}

.overlay {
  background-color: #000000;
}

@media only screen and (max-width: 1200px) {
  .section_no_background {
    width: calc(100vw - 24px);
  }
  
  .video_row {
    flex-direction: column;
    gap: 16px;
  }
  
  .play_block,
  .play_block .video {
    width: 343px;
    height: 260px;
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 992px) {
  .section_full_black_background {
    padding: 40px 0 40px 0;
  }
  .section_no_background {
    width: 100vw;
  }
}
