.section_no_background {
    width: 1200px;
    position: relative;
  }
  .start_today_block {
    width: 100%;
    min-height: 356px;
    background-color: black;
    background-image: url("../../../images/bg/pie-chart1.png"),
      url("../../../images/bg/pie-chart2.png"), url("../../../images/bg/moniki.png");
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: 93px 106px, 112px 112px, 175px 175px;
    background-position: bottom 19px right 217px, top 30px right 52px,
      top 31px left 41px;
    padding: 64px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    gap: 16px;
  }
  
  .tag_black {
    display: flex;
    width: fit-content;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 8px;
    background: rgba(255, 255, 255, 0.16);
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
  }
  
  .title_black {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    color: #ffffff;
  }
  
  .parent_bonus_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .bonus_black {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }
  .bonus_black span {
    text-transform: uppercase;
    color: #f8d210;
  }
  .description_black {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
  
  .button_row_black {
    padding-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
  .btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
  }
  .btn span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
  
  .btn span:hover {
    font-weight: 700;
  }
  
  .enrol_now_yellow {
    background: #f8d210;
    color: #000000;
    border: 2px solid #f8d210;
    width: 205px;
    height: 46px;
  }
  .enrol_now_yellow span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
  
  .enrol_now_yellow span:hover {
    font-weight: 700;
  }
  
  .download_brochure_black {
    background: none;
    color: #ffffff;
    border: 2px solid #ffffff;
    width: 203px;
    height: 46px;
  }
  .download_brochure_black:disabled {
    background: #d8d8d8;
    border: 2px solid #d8d8d8;
    color: #333333;
  }
  /* .download_brochure_black:hover {
    font-weight: 500;
  } */
  
  @media only screen and (max-width: 1200px) {
    .section_no_background {
      width: calc(100vw - 24px);
    }
  }
  
  @media only screen and (max-width: 992px) {
    .section_no_background {
      width: 100vw;
      margin-top: 2.5rem;
    }
    .start_today_block {
      background-color: black;
      background-image: url("../../../images/bg/pie-chart1.png"),
      url("../../../images/bg/pie-chart2.png"), url("../../../images/bg/0,5org.png");
      background-repeat: no-repeat, no-repeat, no-repeat;
      background-size: 93px 106px, 112px 112px, 76px 151px;
      background-position: bottom 103px right -14px, top 18px right -20px,
        top 50% left 0px;
      padding: 40px 12px;
    }
    .title_black {
      font-size: 20px;
      line-height: 30px;
    }
    .bonus_black,
    .description_black {
      text-align: center;
    }
  
    .bonus_black span {
      display: block;
    }
    .button_row_black {
      flex-direction: column;
    }
  }
  