.modal-shadow {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 34rem 10rem 2rem 10rem;
  bottom: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.39);
  z-index: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  overflow-y: scroll;
  overflow-x: clip;
}

.inner {
  /* max-height: 714px; */
  height: 910px;
  position: relative;
  background: black;
  color: #fff;
  padding: 2rem;
  bottom: 250px;
  /* overflow-y: scroll; */
}

.top_btn {
  float: right;
}

.modal_body {
  gap: 4rem;
  margin: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top_span {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 4px;
  width: fit-content;
  padding: 8px 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_body_left {
  width: 480px;
  flex-shrink: 0;
  height: 100%;
}

input {
  width: 100%;
  font-size: 12px;
  line-height: 12px;
  height: 44px;
  padding: 16px;
  border: 2px solid #ffffff;
  border-radius: 4px;
  background-color: black;
  color: #ffffff;
}

input::placeholder {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;

  color: #D8D8D8;
}

.modal_header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  /* padding: 1rem; */
  margin-top: 1rem;
}

.sub_header {
  color: #f8d210;
}

.modal_desc {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 1rem;
}

.modal_list {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.modal_list>img {
  Width: 16px;
  Height: 11.99px
}

.modal_body_right {
  background: rgba(255, 255, 255, 0.1);
  width: 484px;
  padding: 30px 25px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  /* overflow-y: scroll; */
}

.modal_body_right>div {
  margin-bottom: 1rem;
}

.modal_list {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.modal_list>img {
  width: 16px;
  height: 11.99px;
}

.modal_body_right {
  background: rgba(255, 255, 255, 0.1);
  width: 484px;
  padding: 30px 25px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
}

.modal_body_right>div {
  margin-bottom: 1rem;
}

.name_input {
  display: flex;
  gap: 10px;
  margin-bottom: 1rem;
}

.contry_drop {
  /* width: 97%; */
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  position: relative;
  bottom: 50px;
  /* right: -11px; */
}

select option:first-child {
  display: none;
}

.flex {
  display: flex;
  margin-top: 1rem;
  gap: 29px;
  justify-content: flex-start;
}

.check_box {
  /* border: 2px solid #FFFFFF; */
  border-radius: 2px;
  width: 18px;
  height: 18px;
}

.check_text {
  position: relative;
  color: #d8d8d8;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  width: 394px;
  height: 60px;
  margin-bottom: 1rem;
  left: -13px;
}

.optional_label {
  margin-bottom: 1rem;
}

.phone_label {
  position: relative;
  bottom: -8px;
}

.submit_btn {
  background: #f8d210;
  border-radius: 4px;
  display: flex;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 16px;
  width: 420px;
  height: 46px;
  color: black;
  transition-duration: 0.5s;
}

.submit_btn:hover {
  font-weight: 700;
}

.contry_border {
  position: relative;
  width: 40%;
  height: 44px;
  padding: 16px;
  border: 2px solid #ffffff;
  border-radius: 4px;
  background-color: black;
  color: #ffffff;
  bottom: -10px;
  /* left: -4px; */
}

.dropdown-input {
  height: 44px;
  padding: 10px;
  display: block;
  width: 100%;
  font-size: 12px;
  line-height: 12px;
  color: #d8d8d8;
  background-color: #040404;
  background-clip: padding-box;
  border: 2px solid #fff;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

select:focus {
  border: 2px solid #f8d210;
  outline: 2px solid #f8d210;
}

option:hover {
  background: #f5f5ef;
}

/* .dropdown-input:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
} */

.check_box input[type="checkbox"] {
  display: none;
}

.check_box .checkmark {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
}

.check_box .checkmark::before {
  content: "";
  display: block;
  position: absolute;
  top: 20%;
  left: 50%;
  width: 67%;
  height: 20%;
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg) translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.check_box input[type="checkbox"]:checked+.checkmark::before {
  opacity: 1;
}

.position_form {
  position: relative;
  top: -46px;
}

/* select{
  background: black;
  color: #fff;
  padding-right: 20px !important; 
  background-position: right center; 
  background-repeat: no-repeat; 
  -webkit-appearance: none; 
  -moz-appearance: none; 
  appearance: none; 
  background-image: url('../../../images/Down_Arrow_3_.svg'); 
} */

@media only screen and (max-width: 1200px) {
  .modal-shadow{
    width: 100%;
  }
  .inner{
    width: auto;
  }
  .top_btn {
    margin-top: 0.7rem;
  }
  .modal_body {
    gap: 1%;
    margin-left: 1%;
    margin-right: 1%;
  }
  .modal_body_left{
    flex-shrink: 0;
  }
  .modal_body_right {
    width: 50%;
    flex-shrink: 1;
  }
}

@media only screen and (max-width: 992px) {
  .modal-shadow {
    overscroll-behavior: unset;
    padding: 0rem 1rem;
    height: 100%;
    max-width: none;
  }

  .inner {
    position: relative;
    top: 400px;
    bottom: 10rem;
    max-width: 100%;
    margin: 0rem auto;
    /* padding-bottom: 3rem; */
    background: black;
    color: #fff;
    /* overflow-y:unset; */
    border: 1px solid white;
    border-radius: 3px;
    height: auto;
    max-height: none;
  }

  .modal_body {
    display: flex;
    flex-direction: column;
    margin: 4px;
  }

  .modal_header {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    margin-top: 3px;
    width: 311px;
    height: 60px;
  }

  .modal_desc {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 5px;
    width: 311px;
    height: 120px;
  }

  .name_input {
    display: flex;
    flex-direction: column;
    gap: 5px;
    /* margin-bottom: 5px; */
  }

  .modal_body_left {
    max-width: 461px;
    height: 295px;
    margin-bottom: 18px;
  }

  .top_span {
    background: rgba(255, 255, 255, 0.15);
    border-radius: 4px;
    width: 257px;
    height: 36px;
    padding: 8px 16px;
    font-family: "Poppins";
    font-style: normal;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal_list {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    gap: 10px;
    align-items: baseline;
  }

  .modal_body_right {
    position: relative;
    background: rgba(255, 255, 255, 0.1);
    width: 484px;
    /* padding: 20px; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    max-width: 390px;
    max-height: 840px;
    bottom: -20px;
    /* padding: 2rem; */
    /* margin-bottom: 10rem; */
    /* overflow-y: auto; */
  }

  .submit_btn {
    position: relative;
    right: -20px;
    background: #F8D210;
    border-radius: 4px;
    display: flex;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    gap: 16px;
    width: 311px;
    height: 46px;
    color: black;
    transition-duration: 0.5s;
  }

  input {
    width: 100%;
    height: 44px;
    padding: 17px;
    border: 2px solid #ffffff;
    border-radius: 4px;
    background-color: black;
    color: #ffffff;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 12px;
  }

  .phone_label {
    position: relative;
    bottom: -14px;
  }

  .contry_border {
    border: 2px solid #fff;
    border-radius: 4px;
    color: #fff;
    height: 44px;
    position: relative;
    bottom: -15px;
    /* width: 18%; */
    padding: 6px;
  }

  .phone-input {
    width: 100%;
    height: 12px;
    padding: 10px;
    border: 2px solid #ffffff;
    border-radius: 4px;
    color: #ffffff;
    background-color: black !important;
  }

  .phone-dropdown {
    font-size: 16px;
    border: 1px solid #ccc;
    background-color: #000;
    padding: 10px;
    color: #fff;
  }

  label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #fcfcf5;
  }

  .contry_drop {
    /* width: 97%; */
    margin-top: 21px;
    margin-bottom: 5px;
  }

  .flex {
    display: flex;
    margin-top: 1rem;
    gap: 29px;
    justify-content: flex-start;
  }

  .check_text {
    color: #d8d8d8;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    width: 285px;
    height: 80px;
    margin-bottom: 1rem;
  }

  .dropdown-input {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    padding: 5px;
  }

  .check_box {
    border-radius: 2px;
    width: 18px;
    height: 18px;
  }
}

@media only screen and (max-width: 550px) {
  .modal-shadow{
    padding: 4px;
  }
  .inner {
   padding: 25px 20px 21px 31px;
  }

  .top_span {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    width: 260px;
  }

  .modal_body {
    justify-content: center;
    align-items: center;
    margin: 0px;
  }

  .modal_desc,
  .modal_header {
    max-width: none;
    width: 100%;
    max-height: none;
    height: auto;
  }

  .modal_body_left,
  .modal_body_right {
    max-width: none;
    width: 100%;
    max-height: none;
    height: 100%;
    margin: 0px;
  }

  .submit_btn {
    position: relative;
    width: 100%;
    margin-top: 16px;
    padding: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}