.authWrapper {
    width: 100vw;
    height: 100vh;
    background-color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .authHeader {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
  }
  
  .authSubTitle {
    color:#FCFCF5;
    text-align: center;
    font-size: 16px;
    line-height: 28px;
  }
  
  .authContent, .authContentModal {
    display: flex;
    width: 484px;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    margin: 208px 0px;
  }

  .authContentModal {
    margin: auto !important;
    padding: 50px 0px;
  }
  
  .imageWrapper {
    width: 80px;
    height: 35.2px;
  }
  
  .formWrapper {
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
  }
  
  .formLabel {
    color: var(--secondary-light, #fcfcf5);
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
  .accountTextWrapper {
    display: flex;
    align-items: center;
  }
  
  .accountText,
  .signUpText {
    color: #D8D8D8;
    font-size: 12px;
    line-height: 20px;
  }
  
  .signUpText {
    text-decoration: underline;
    margin-left: .3rem;
  }

  .btnPasswordSuccess{
    width: 290px;
  }
  
  @media only screen and (max-width: 992px) {
    .authContent, .authContentModal  {
      width: 100%;
      padding: 1rem;
    }
  }
  