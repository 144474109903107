.section_no_background {
    width: 1200px;
    position: relative;
    margin: auto;
  }
  .top_banner {
    background-color: #f8d210;
    background-image: url("../../images/bg/404Bg.png");
    background-repeat: no-repeat;
    background-position: top 14px right 9px;
  
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 64px 0 101px;
    margin: 120px 0px;
    width: 100%;
  }
  
  .banner_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
  }
  
  .tag {
    padding: 8px 16px;
    width: fit-content;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
  }
  
  .banner_text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 492px;
  }
  .banner_title {
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 60px;
    color: #000000;
  }
  
  .banner_message {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
  }
  
  .banner_button {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
  
  .enrol_now {
    background: #000000;
    color: #ffffff;
    width: 133px;
    height: 46px;
  }
  
  .banner_photo img {
    margin-bottom: -5px;
  }
  
  @media only screen and (max-width: 1200px) {
    .section_no_background {
      width: calc(100vw - 24px);
    }
  }
  
  @media only screen and (max-width: 992px) {
    .section_no_background {
      width: 100vw;
    }
    .top_banner {
      background-image: none;
      background-color: #f8d210;
  
      flex-direction: column;
      align-items: center;
      padding: 64px 16px 0 16px;
      margin-top: 70px;
      margin-bottom: 0px;
    }
  
    .banner_photo {
      width: 375px;
      overflow: hidden;
      background-image: url("../../images/bg/404Mobile.png");
      background-position: bottom center;
      background-repeat: no-repeat;
    }
    .banner_photo img {
      width: 100%;
      height: 100%;
    }
  
    .banner_info {
      width: 343px;
    }
  
    .banner_title {
      font-size: 35px;
      line-height: 42px;
    }
  
    .banner_message {
      font-size: 18px;
      line-height: 27px;
    }
  
    .banner_button {
      justify-content: space-between;
      flex-direction: column;
    }
    .banner_button button {
      width: fit-content;
    }
  
    .enrol_now {
      padding: 16px 44px;
    }

  }
  