.section_no_background {
  width: 1200px;
  position: relative;
  margin: auto;
}

.top_banner {
  background-color: #f8d210;
  background-image: url("../../../images/sss/block.png"),
    url("../../../images/sss/dd.png"),
    url("../../../images/sss/stars.png");
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: 137px 137px, 121px 121px, 202px 168px;
  background-position: bottom 50px left 51px, bottom 14px left 60%,
    top 26px right 57px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
  width: 100%;
  height: 292px;
  gap: 8px;
  border-radius: 4px;
}

.inf_block {
  width: 653px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.banner_title {
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  color: #000000;
  text-align: center;
}

.banner_text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  text-align: center;
}

@media only screen and (max-width: 1200px) {
  .section_no_background {
    width: calc(100vw - 24px);
  }
}

@media only screen and (max-width: 992px) {
  .section_no_background {
    width: 100vw;
  }
  .top_banner {
    margin-top: 70px;
    height: 244px;
    background-image: none;
    border-radius: 0;
  }
  .inf_block {
    width: 343px;
  }
  .banner_title {
    font-size: 35px;
    line-height: 42px;
  }
}
