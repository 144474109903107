.section_no_background {
  width: 1200px;
  position: relative;
  margin: auto;
}

.centered_block {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 80px 0 104px 0;
}
@media only screen and (max-width: 1200px) {
  .section_no_background {
    width: calc(100vw - 24px);
  }
}

@media only screen and (max-width: 992px) {
  .section_no_background {
    width: 100vw;
  }
  .centered_block {
    padding: 60px 0 64px 0;
  }
}
