.section_no_background {
  width: 1200px;
  position: relative;
}

.sss_title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #1e1b2e;
}

.description_courses_text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #000000;
}
.slider_block {
 align-items: baseline;
    display: flex;
    flex-direction: row;
    height: 462px;
    justify-content: center;
    margin-top: 40px;
    position: relative;
    width: 100%;
    align-content: stretch;
    flex-wrap: wrap;
 }

 .justify-end{
  justify-content: flex-end;
 }

@media only screen and (max-width: 1200px) {
  .section_no_background {
    width: calc(100vw - 24px);
  }
}

@media only screen and (max-width: 992px) {
  .section_no_background {
    width: 100vw;
    height: 100vh;
  }
  .sss_title {
    font-size: 20px;
    line-height: 30px;
  }
  .description_courses_text {
    padding-left: 16px;
    padding-right: 16px;
  }

  .slider_block {
    height: 300px;
    width: 342px;
    margin-left: calc(50% - 171px);
    padding: 0;
    position: relative;
 }
}
