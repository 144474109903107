.section{
    width: 1200px;
    background:  #000000;
    border-radius: 4px;
}

.block{
    width: 100%;
    min-height: 327px;
    background-color: black;
    background-image: url("../../images/bg/pie-chart1.png"),
      url("../../images/bg/pie-chart2.png"), url("../../images/bg/moniki.png");
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: 112px 112px, 112px 112px, 175px 175px;
    background-position: bottom 19px right 217px, top 30px right 52px,
      top 31px left 41px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap:1rem;
      padding: 50px 0;
}

.tag_black {
  display: flex;
  width: fit-content;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 8px;
  background: rgba(255, 255, 255, 0.16);
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
}
.text {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
}

.span{
  color: #f8d210;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.button{
  background: #f8d210;
  color: #000000;
  border: 2px solid #f8d210;
  width: 233px;
  height: 46px;
}

@media only screen and (max-width: 1200px) {
  .section{
    width: calc(100vw - 24px);
  }
}

@media only screen and (max-width: 992px){
  .section{
    width: 100%;
  }

  .block{
      background-image: url("../../images/bg/pie-chart1.png"),
      url("../../images/bg/0,5org.png");
      background-repeat: no-repeat, no-repeat;
      background-size: 93px 106px, 76px 151px;
      background-position: bottom 4rem right -5px, 
        top 5% left 0px;
      padding: 40px 12px;
  }
  .text{
    width:98%;
    font-size: 20px;
  }
}