.section_for_background {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fcfcf5;
  background-image: url("../../images/bg/Rectangle846.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% 463px;
}

.start_your {
  width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 21px;
}

.start_your_title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  text-align: left;
  color: #1e1b2e;
}

.start_your_inf {
  max-width: 515px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.start_your_text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
}

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  border: 2px solid #000000;
  transition: all 0.8s;
}
.btn span {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btn span:hover {
  font-weight: 700;
}

.start_your_btn {
  margin-top: 8px;
  width: 199px;
  height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  background: #000000;
  border-radius: 4px;
  border: none;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
}

.start_your_btn:hover {
  font-weight: 700;
}

.img_or_calendar img {
  margin-bottom: -5px;
}

.calendly_widget {
  width: 507px;
  height: 602px;
  box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 40px;
}

@media only screen and (max-width: 1200px) {
  .start_your {
    width: calc(100vw - 24px);
  }
}

@media only screen and (max-width: 992px) {
  .start_your {
    width: 100vw;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
  .start_your_title {
    font-size: 20px;
    line-height: 30px;
  }

  .start_your_inf {
    width: 343px;
  }

  .img_or_calendar {
    width: 343px;
  }
  .img_or_calendar img {
    width: 100%;
    margin-bottom: -5px;
  }

  .calendly_widget {
    width: 342px;
    height: 750px;
    background-color: #ffffff;
  }
}
