.modal-shadow {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 4rem 10rem;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.39);
  z-index: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
}

.image img.loading {
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.image img.loaded {
  opacity: 1;
}

.placeholder {
  width: 100%;
  height: auto;
  background-color: #f0f0f0; /* Light grey as a placeholder color */
}


.inner {
  width:1200px;
  background: black;
  color: #fff;
  padding: 0.5rem;
  bottom: 21px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
}

  .top_btn{
    float: right;
    }

    .main{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      background-image: url('../../../images/Promobarchart.png '), url('../../../images/PromoPattern1.png'),
      url('../../../images/PromoPiechart.png');
      background-repeat: no-repeat, no-repeat, no-repeat;
      background-size: 103px 103px, 91px 91px, 120px 120px;
      background-position: top 40px left 70%, bottom 0 left 65%,top 100px right -5px;
      }

      .textdiv{
        display: flex;
        flex-direction: column;
        padding-top: 0.5rem;
        padding-left: 3rem;
        align-items: left;
        justify-content: center;
        gap: 1rem;
       }

       .tag{
        width: 168px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        background:rgba(202, 198, 198, 0.15);
        display: flex;
        align-items: flex-start;
        padding: 8px 16px;
        gap: 5px;
      }
      
    .heading{    
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      width: 100%;
      line-height: 60px;
    }
  
    .subtext{
      color: #f8d210;
    }

    .text{
      width: 100%;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
    }

    .detailbtn{
      width: 198px;
      height: 46px;
    }

.image{
  margin-bottom: -7px;
  width: 60%;
}

@media screen and (max-width: 1200px){
  .modal-shadow{
    padding:2rem 1rem;
  }
  .inner{
    width:98%
  }

  .image{
    margin-top: 10%;
  }
}

@media screen and (max-width: 1000px){
  .inner{
    width: 95%;
  }
.main{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    background-image: url('../../../images/Promobarchart.png '), url('../../../images/PromoPattern1.png'),
    url('../../../images/PromoPiechart.png');
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: 71.1px 71.1px, 61px 61px, 87.66px 87.66px;
    background-position: top 330px left 5%, bottom 0 left 5%,top 450px right 10%;
  }

  .textdiv{
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-left: 0;
    gap: 1.5rem;  
    width: 100%;     
  }

  .tag{
    width: 168px;
    height: 36px;
    left: 32px;
    top: 136px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    background:rgba(202, 198, 198, 0.15);
  }

  .heading{    
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    width: 100%;
    line-height: 30px;
  }
  
  .text{
    width: 100%;
    top: 244px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px; 
  }

  .detailbtn{          
    width: 198px;
    height: 46px;
    
    }
    .image{
      width: 100%;
      margin-left: 25%;
    }
}

@media only screen and (max-width: 750px){
.modal-shadow{
  overflow-y: scroll;
  overflow-x: clip;
}
.inner{
  width: 343px;
  margin-top: 10rem;
  padding-top: 1rem;
  padding-right: 1rem;
  }

.main{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    background-image: url('../../../images/Promobarchart.png '), url('../../../images/PromoPattern1.png'),
    url('../../../images/PromoPiechart.png');
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: 71.1px 71.1px, 61px 61px, 87.66px 87.66px;
    background-position: top 330px left 5%, bottom 0 left 2%,top 350px right -5%;
  }

  .textdiv{
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-left: 0;
    gap: 1rem;  
    width: 100%;    
  }

  .tag{
    width: 168px;
    height: 36px;
    left: 32px;
    top: 136px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    background:rgba(202, 198, 198, 0.15);
  }

  .heading{    
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    width: 100%;
    line-height: 30px;
  }
  
  .text{
    width: 100%;
    top: 244px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px; 
  }

  .detailbtn{         
    width: 198px;
    height: 46px;    
    }

    .image{
        width: 100%;
        display: block;
         margin-left: auto;
        margin-right: auto; 
     }
}
