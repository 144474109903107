.section_first_background {
  /* max-width: 1350px; */
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;
}

/* img{
  height: 120px;
} */

.section_no_background_carousel {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.student_success_stories {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.sss_carousel_row {
  width: 100%;
}

.sss_title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #1e1b2e;
}

.parent_carousel_in_section {
  width: 1332px;
  position: relative;
}

.carousel_body {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.gold_border {
  border: 2px solid #f8d210;
}

.arrow_column {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
}

.arrow_wrapper {
  position: relative;
}

.left {
  justify-content: flex-start;
  /* left: 0; */
  top: 58%;
}

.right {
  justify-content: flex-end;
  right: 10px;
  top: 58%;
}

.flex_btn_left2 {
  position: absolute;
  display: inline;
  left: 200px;
  bottom: 100px
}

.flex_btn_right2 {
  position: absolute;
  display: inline;
  right: 240px;
  bottom: 100px
}

.circle_arrow_btn {
  width: 48px;
  height: 48px;
  border-radius: 100px;
  border: none;
  background-color: #f8d210;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: black;
  overflow: hidden;
}

.slide_column {
  width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* gap: 24px; */
  padding-bottom: 64px;
}

.pagination {
  position: absolute;
  margin: 0 auto;
  width: 90%;
  align-items: center;
  cursor: pointer;
  bottom: 9px;
  right: 66px;
  height: 36px;
}

.barContainer {
  height: 6px;
  background-color: #ddd;
  border-radius: 2px;
  overflow: hidden;
  margin: 16px 0;
  position: relative;
}

.bar {
  height: 100%;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 33.33%;
  transition: width 0.5s ease-in-out;
}

.view_port {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  border-radius: 4px;
}

.slide_list {
  display: flex;
  flex-direction: row;
  transition: all 0.8s;
  width: 100%;
}

.slide_list .active {
  transition: all 0.8s;
  opacity: 1;
  z-index: 1;
}

.slide_block {
  width: 1200px;
  position: relative;
  border-radius: 4px;
  opacity: 0;
  display: flex !important;
  flex-direction: row;
  gap: 16px;
  z-index: -1;
}

.big_avatar {
  width: 450px;
  height: 490px !important;
  height: fit-content;
  overflow: hidden;
  border-radius: 8px;
}

.big_avatar img {
     object-fit: cover;
    width: 100%;
    height: -webkit-fill-available;
}

.inf_block {
  width: 690px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 8px;
  background: #ffffff;
  box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
  border-radius: 4px;
  height: 490px;
}

.small_avatars_row {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  margin: auto;
  width: 800px;
  justify-content: center;
}

.no_slider {
  margin-bottom: 2rem;
}

.small_avatar {
  width: 136px;
  height: 120px;
}

.small_avatar .parent_img {
  margin-right: 16px;
  width: 110px;
  height: 110px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
}

.parent_img > img {
    height: -webkit-fill-available;
    object-fit: cover;
    width: 100%;
}

.name_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1e1b2e;
}

.name_row span {
  text-transform: uppercase;
}

.name_row a {
  text-decoration: none;
  width: 24px;
  height: 24px;
  color: #000000;
}

.name_row a svg {
  text-decoration: none;
  border: none;
  width: 100%;
  height: 100%;
}

.social_row {
  display: flex;
  align-items: center;
}

.positions_row {
  width: 100%;
  /* overflow: scroll; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.positions_row::-webkit-scrollbar {
  display: none;
}

.position {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 8px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  width: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
}

.instructor_description {
 padding: 8px 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
    overflow-y: auto;
    height: 500px;
    overflow-x: hidden;
}

.logos {
  width: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.logos::-webkit-scrollbar {
  display: none;
}

.logos img {
  filter: grayscale(100%);
}

@media screen and (max-width: 1332px) {
  .section_no_background_carousel {
    width: 1200px;
  }

  .parent_carousel_in_section {
    width: 100%;
    position: initial;
  }

  .flex_btn_left2 {
    left: 0px;
  }

  .flex_btn_right2 {
    right: 0px;
  }

  .slide_column {
    width: 1068px;
  }

  .slide_block {
    width: 1068px;
  }

  .inf_block {
    width: 558px;
  }

  .big_avatar {
    width: 50%;
    height: inherit;
  }

  .small_avatars_row {
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .small_avatars_row::-webkit-scrollbar {
    display: none;
  }

  .small_avatar .parent_img {
    width: 120px;
    height: 120px;
  }
}

@media screen and (max-width: 1200px) {
  .section_no_background_carousel {
    width: calc(100vw - 24px);
  }

  .slide_column {
    width: 81.98vw;
  }

  .view_port {
    box-shadow: none;
  }

  .slide_block {
    width: 81.98vw;
  }

  .big_avatar img {
    height: 100%;
    width: 100%;
  }

  .inf_block {
    width: 48.3%;
  }
}

@media screen and (max-width: 992px) {
  .section_no_background_carousel {
    width: 100vw;
  }

  .sss_title {
    font-size: 20px;
    line-height: 30px;
  }

  .carousel_body {
    flex-direction: column;
    gap: 32px;
  }

  .slide_column {
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }

  .view_port {
    width: 100%;
  }

  .slide_block {
    flex-direction: column;
    width: calc(100vw - 24px);
  }

  .inf_block {
    margin-top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 8px;
    background: #ffffff;
    box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
    border-radius: 4px;
    height: 400px;
  }

  .big_avatar {
    width: 100%;
    height: 343px !important;
    display: flex;
    justify-content: center;
  }

  .big_avatar img {
    width: 343px;
    height: 343px;
  }

  .arrow_column {
    top: 0;
  }

  .arrow_wrapper {
    position: initial;
  }

  .left {
    left: 12px;
  }

  .right {
    right: 12px;
  }

  .flex_btn_left2,
  .flex_btn_right2 {
    display: none !important;
  }

  .slide_paginate {
    width: calc(100% - 144px);
    margin-left: 72px;
  }

  .small_avatars_row {
    width: 500px;
  }

  .small_avatars_row::-webkit-scrollbar {
    display: none;
  }

  .small_avatar {
    width: 72px;
    height: 72px;
    border: 2px solid transparent;
  }

  .small_avatar .parent_img {
    width: 72px;
    height: 72px;
    border: 2px solid transparent;
  }

  .small_avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .name_row {
    justify-content: space-between;
  }

  .logos {
    overflow: auto;
    flex-wrap: wrap;
  }

  .pagination {
    position: absolute;
    margin: 0 auto;
    width: 90%;
    align-items: center;
    cursor: pointer;
    bottom: 20px;
    right: 16px;
    height: 36px;
  }
}

@media screen and (max-width: 540px) {
  .small_avatars_row {
    position: initial;
    width: 100%;
    padding: 12px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin: 0px;
    display: block;
  }
}