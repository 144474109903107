.modal-shadow {
    overscroll-behavior: unset;
    position: fixed;
    top: 60px;
    left: 0;
    min-height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.39);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(10px);
  }

.modal{
    background-color: black;
    font-family: Poppins;
    color:white;
    margin-top: -10%;
    padding: 1rem; 
    width: 728px;
    box-sizing: border-box;
    border: 1px solid #D8D8D8;
    border-radius: 4px;     
  }

  .top_btn{
    float: right;
  }
  .main{
    display: flex;
    flex-direction: column;
  }
  .heading{    
    text-align: center; 
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    width: 541px;
    margin-left: 2rem;
  }
  
  .subtitle{
    text-align: center; 
    width: 581px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-left: 2rem;
  }
  
  .policydiv{
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 10px;
  }

  .polcies{
    display: flex;
    justify-content: space-evenly;
    width: 648px;    
  }
  
  .policydetails{
    display:flex;
    flex-direction: column;     
    width: 510px;
  }
  
  .buttons{
    display: flex;
    justify-content: space-between;  
    margin-top: 2rem; 
    margin-left: 2rem;
    }
  
  .acceptselected{        
    width: 165.5px;
    height: 30px;    
  }
  .accept{      
    width: 165.5px;
    height: 30px;
  }

  .reject{
    background-color: black;
    border: none;
    color: white;          
    width: 165.5px;
    height: 30px;
    border-radius: 3px
  }
  
.toggle{
    align-self:center;
    }
    .leftbuttons{
      display: flex;
      flex-direction: row;
    }
    
    @media only screen and (max-width: 700px){
        .modal-shadow {
            overscroll-behavior: unset;
            position: fixed;
            top: 62px;
            width: 100vw;
            background: rgba(0, 0, 0, 0.39);
            z-index: 4;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            backdrop-filter: blur(10px);
          }

          .modal{
            box-sizing: border-box;
            width: 98%;
            max-height:550px;
            background: #000000;
            border: 1px solid #D8D8D8;
            border-radius: 4px;
            overflow-y: auto;
          }
         
          .modal::-webkit-scrollbar{
            display: none;
          }
          .main{
              overflow-x: hidden;         
            }

          .heading{
            width: 100%;
            font-weight: 700;
            font-size: 20px;
            margin-left:0;
          }

          .subtitle{
            position:static;
            width: 100%;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            margin-left: 0;         
          }    

          .policydiv{
            margin-top: 1rem;
            width: 100%;
          }     
          .polcies{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 2rem;
            width: 100%;
            border-radius: 3px;                     
          }

          .policydetails{
            width: 95%;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #D8D8D8;            
            flex-grow: 0;
          }

          .buttons{
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            gap: 10px;
            margin-top: 55px;
            margin-left: 0;
          }     
       

          .acceptselected{
            width: 300px;
            height: 30px;
          }           
        
    }