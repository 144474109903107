.section_no_background {
  width: 1200px;
  position: relative;
  margin: auto;
}

.three_section_margin {
  margin-bottom: 104px;
  margin-top: 6rem;
}

 @media only screen and (max-width: 992px) {
  .three_section_margin{
    margin-bottom: 0;
  }
  }

@media only screen and (max-width: 992px){
  .section_no_background {
      width: 100vw;
  }
}
.item_in_center {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.get_involved {
    width: 735px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}
@media only screen and (max-width: 992px){
  .get_involved {
      width: 342px;
  }
}
.text_in_center {
    text-align: center;
    padding-right: 58px;
}

.title_two_type {
    width: 328px;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: #1e1b2e;
}

@media only screen and (max-width: 992px){
  .title_two_type {
      font-size: 20px;
      line-height: 30px;
      padding-left: 46px;
  }
}

.involved_button_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
}
@media only screen and (max-width: 992px){
  .involved_button_row {
      flex-direction: column;
  }
}
.involved_btn_second {
    background: none;
    color: #000;
}

@media only screen and (max-width: 992px){
  .involved_btn_second{
    width: 229px;
  }
}
.involved_btn {
    height: 46px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    gap: 16px;
    border: 2px solid #000;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
}

.btn {
  width: 299px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.last_section {
  margin-bottom: 104px;
}
