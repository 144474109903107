.name_inputs {
  display: flex;
  gap: 10px;
  margin-bottom: 1rem;
}

.label {
  position: relative;
  top: -11px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #fcfcf5;
}

.contry_flag {
  align-items: center;
  background-color: #000;
  bottom: -1px;
  display: flex;
  justify-content: center;
  left: 2px;
  padding: 17px;
  position: relative;
  width: 16%;
  border: 2px solid #fff;
  border-radius: 4px;
  color: #fff;
  height: 44px;
}

.contry_dropdown {
  display: flex;
  align-items: center;
  width: 95%;
  position: relative;
  left: 22px;
  top: -43px;
}

.position_form {
  position: relative;
  top: -46px;
}

.optional_label {
  margin-bottom: 1rem;
}

.dropdown-input {
  display: block;
  width: 100%;
  padding: 12.5px;
  font-size: 12px;
  line-height: 12px;
  color: #D8D8D8;
  background-color: #040404;
  background-clip: padding-box;
  border: 2px solid #fff;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.top_btn {
  position: relative;
  right: -412px;
  top: -8px;
}

select option:first-child {
  display: none;
}

option:hover {
  background: #F5F5EF;
}

.flex {
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  gap: 24px;
}

.check_box {
  border-radius: 2px;
  width: 18px;
  height: 18px;
}

.check_text {
  position: relative;
  color: #D8D8D8;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  width: 394px;
  height: 60px;
  margin-bottom: 1rem;
  left: -13px;
}

.check_box input[type="checkbox"] {
  display: none;
}

.check_box .checkmark {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
}

.check_box .checkmark::before {
  content: '';
  display: block;
  position: absolute;
  top: 20%;
  left: 50%;
  width: 67%;
  height: 20%;
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg) translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.check_box input[type="checkbox"]:checked+.checkmark::before {
  opacity: 1;
}

.submit_btn {
  background: #F8D210;
  border-radius: 4px;
  display: flex;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 16px;
  width: 420px;
  height: 46px;
  color: black;
  transition-duration: 0.5s;
}

.submit_btn:hover {
  font-weight: 700;
}

.form {
  cursor: default;
}

@media only screen and (max-width: 992px) {

  .modal_body_right {
    background: black;
    bottom: -20px;
    left: -16px;
    right: 21px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 750px;
    max-width: 390px;
    padding: 20px;
    position: relative;
    width: 343px;
    margin-bottom: 6rem;
    border: 2px solid #D8D8D8;
  }

  .contry_dropdown {
    display: flex;
    align-items: center;
    width: 95%;
    position: relative;
    left: 12px;
    top: -43px;
  }

  .flex {
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 1rem;
  }

  .top_btn {
    float: right;
    position: initial;
  }

  .contry_flag {
    align-items: center;
    background-color: #000;
    bottom: -1px;
    display: flex;
    justify-content: center;
    left: 2px;
    padding: 17px;
    position: relative;
    width: 18%;
    border: 2px solid #fff;
    border-radius: 4px;
    color: #fff;
    height: 44px;
  }

  .submit_btn {
    background: #F8D210;
    border-radius: 4px;
    display: flex;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    gap: 16px;
    width: 313px;
    height: 46px;
    color: black;
    transition-duration: 0.5s;
    position: relative;
    left: -7px;
  }

  .form-margin {
    margin-bottom: 30rem;
  }
}

@media only screen and (max-width: 412px) {
  .modal_body_right {
    background: black;
    bottom: -20px;
    left: -16px;
    right: 21px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 750px;
    max-width: 390px;
    padding: 20px;
    position: relative;
    width: 343px;
    margin-bottom: 6rem;
  }
}