.section_no_background{
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  width: 82vw;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
}
.student_success_stories{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-bottom: 2rem;
}

.arrowNext{
  font-size: 2rem;
  display: none;
}

.prevArrow{
  font-size: 2rem;
  display: none;
}

.sss_carousel_row{
  height: 395px;
    width: 90%;
    overflow: hidden;
    position: relative;
    flex-direction: row;
    box-shadow: 1px 3px 20px rgb(1 1 1 / 7%);
    border-radius: 4px;
}

.container{
      width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    background: #fff;
    border-radius: 4px;
    transition: .8s;
}

.left_success{
        width: 100%;
    max-width: 493px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 3.5rem;
}

.success_text{
  width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333;
}

.line{
  border: 1px solid #d8d8d8;
  width: 100%;
}

 .test-span {
    font-weight: 700;
    font-size: 50px;
    line-height: 30px;
    color: #F8D210;
  }

  .right_success{
    width: 348px;
    height: 314px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    left: 10px;
  }

  .frame{
    width: 281px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

  .image_block{
    width: 281px;
    height: fit-content;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    overflow: hidden;
    background-image: url("../../images/bg/Ellipse114.png");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 277px 277px;
  }

  .label_block{
    position: absolute;
    bottom: 0;
    right: 0;
    width: fit-content;
    height: 80px;
    background: #fff;
    box-shadow: 1px 3px 20px rgba(1,1,1,.07);
    border-radius: 5px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .label_title{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1e1b2e;
  }

  .label_text{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #333;
    opacity: .8;
  }
  .flex_btn_left{
    position: absolute;
    left: 0;
  }
  .flex_btn_right{
    position: absolute;
    right: 0;
  }
  
  

  .pagination{
 position: absolute;
    margin: 0 auto;
    width: 81%;
    align-items: center;
    cursor: pointer;
    bottom: -19px;
    height: 35px;
  }

.barContainer {
  height: 6px;
    background-color: #ddd;
    border-radius: 2px;
    overflow: hidden;
    margin: 16px 0;
    position: relative;
}

.bar {
  height: 100%; 
  background-color: #000; 
  position: absolute; 
  top: 0;
  left: 0;
  width: 33.33%; 
  transition: width 0.5s ease-in-out; 
}
@media only screen and (max-width: 992px){
  .section_no_background {
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    width: 100vw;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 auto;
}
  .sss_carousel_row{
    width: 100%;
    overflow: hidden;
    position: relative;
    flex-direction: row;
    box-shadow: 1px 3px 20px rgb(1 1 1 / 7%);
    border-radius: 4px;
    height: 670px;
}
.pagination{
   margin: 0 auto;
    width: 81%;
    align-items: center;
    cursor: pointer;
    height: 36px;
    position: absolute;
    bottom: -11px;
}
.container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #fff;
    border-radius: 4px;
    transition: .8s;
    padding: 20px 12px;
    width: calc(100vw - 24px);
}
.left_success {
        position: relative;
    width: 100%;
    max-width: 493px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    top: -32px;
    right: 7px;
}
.right_success {
    width: 340px;
    height: 352px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}
.student_success_stories {
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-bottom: 2rem;
}

.flex_btn_left {
    position: absolute;
    top: 6px;
    left: 12px;
}
.flex_btn_right {
   top: 6px;
    position: absolute;
    right: 12px;
}
.btn_size {
    position: relative;
    margin: 0 auto;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.right_success {
    width: 340px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}
.frame {
        width: 281px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
.label_block{
    position: absolute;
    bottom: 35px;
    right: 0;
    width: 267px;
    height: 80px;
    background: #fff;
    box-shadow: 1px 3px 20px rgb(1 1 1 / 7%);
    border-radius: 5px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.success_text {
  padding: 6px;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #333;
}
.centered{
      position: relative;
    margin-bottom: 50px;
    bottom: -18px;
}
}

@media only screen and (max-width: 600px){

.label_block {
    position: absolute;
    bottom: 0;
    right: 38px;
    width: fit-content;
    height: 80px;
    background: #fff;
    box-shadow: 1px 3px 20px rgba(1,1,1,.07);
    border-radius: 5px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.test-span {
    font-weight: 700;
    font-size: 50px;
    line-height: 30px;
    color: #F8D210;
}
.right_success {
    width: 340px;
    height: 352px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    top: -39px;
    right: -4px;
}
}

.btn_size{
  width: 250px;
}

@media only screen and (max-width: 360px){
  .centered {
    position: relative;
    margin-bottom: 50px;
    bottom: -10px;
}
}