.footer {
   width: 100%;
   min-height: 496px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   gap: 37px;
   background: #000000;
   font-family: Poppins;
}

.first_footer_row {
   width: 1200px;
   padding-top: 64px;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   gap: 8px;
}
.contacts_info {
   display: flex;
   flex-direction: column;
   gap: 22px;
}

.subscribe_box {
   background: rgba(255, 255, 255, 0.1);
   box-shadow: 1px 3px 20px #000000;
   border-radius: 4px;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   padding: 32px;
   gap: 10px;
   width: 357px;
}

.description {
   width: 100%;
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
   color: #ffffff;
}

form {
   width: 100%;
}

.email_input {
   display: flex;
   flex-direction: column;
   width: 100%;
   margin-bottom: 0.5rem;
}

.email_input label {
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 20px;
   color: #fcfcf5;
}

.email_input input {
   width: 100%;
   padding: 16px;
   border: 2px solid #ffffff;
   border-radius: 4px;
   background: none;
   color: #ffffff;
}
.input:focus {
   border: 2px solid #f8d210;
   outline: 2px solid #f8d210;
}

.input::placeholder {
   font-weight: 400;
   font-size: 12px;
   line-height: 12px;
   color: #d8d8d8;
   opacity: 0.6;
}

.submit_email {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   padding: 10px 32px;
   gap: 16px;
   background: #f8d210;
   border: 1px solid #f8d210;
   border-radius: 4px;
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   line-height: 14px;
   color: #000000;
}

.submit_email span {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   gap: 12px;
}

.submit_email span:hover {
   /* transition: all 0.8s;
      transform: scale(1.05); */
   font-weight: 600;
}

.email_and_phone {
   display: flex;
   flex-direction: column;
   gap: 8px;
   padding: 0 32px 0 32px;
}

.email_and_phone  a {
   text-decoration: none;
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 9px;
   color: #d8d8d8;
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
}

.first_row_info {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   gap: 40px;
}

.info_block {
   display: flex;
   flex-direction: column;
   gap: 24px;
   align-items: flex-start;
}

.info_block_title {
   width: 100%;
   white-space: nowrap;
   border-bottom: 2px solid #d9d9d9;
   padding: 9px 0;
   font-style: normal;
   font-weight: 700;
   font-size: 16px;
   line-height: 24px;
   color: #ffffff;
}

.multi_item {
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-column-gap: 40px;
   grid-row-gap: 24px;
}

.sub_sub {
   display: flex;
   flex-direction: column;
   gap: 12px;
}

.sub_sub a {
   text-decoration: none;
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 20px;
   color: #ffffff;
}

.sub_title {
   font-style: normal;
   font-weight: 700;
   font-size: 12px;
   line-height: 20px;
   color: #ffffff;
}

.no_multi_item {
   display: flex;
   flex-direction: column;
   gap: 12px;
}

.no_multi_item a {
   text-decoration: none;
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 20px;
   color: #ffffff;
}

.last_footer_row {
   width: 1200px;
   padding: 24px 0 21px 0;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   border-top: 1px solid #333333;
}

.last_row_info {
   display: flex;
   flex-direction: row;
   justify-content: right;
   align-items: center;
   gap: 48px;
}

.social_block {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   gap: 30px;
   width: 100%;
   max-width: 357px;
}

.social_icon {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   border: none;
   background: none;
   /* color: #F8D210; */
}

.social_icon:hover {
   color: #ffffff;
}

svg {
   text-decoration: none;
   border: 0;
}

.info_title {
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 20px;
   color: #ffffff;
}

.sub_sub_item_text {
   color: #ffffff;
   font-size: 12px;
   transition: all 0.8s;
}

.sub_sub_item_text:hover {
   transition: all 0.8s;
   color: #f8d210;
}

@media only screen and (max-width: 1200px) {
   .first_footer_row {
      width: 100%;
      padding: 64px 12px 0 12px;
   }
   .last_footer_row {
      width: 100%;
      padding: 24px 12px 21px 12px;
   }
}

@media only screen and (max-width: 1148px) {
   .first_footer_row {
      padding: 64px 12px 0 12px;
   }

   .last_footer_row {
      padding: 24px 12px 21px 12px;
   }
}

@media only screen and (max-width: 992px) {
   .first_footer_row {
      padding: 36px 12px 0 12px;
      flex-direction: column;
   }

   .first_row_info {
      flex-wrap: wrap;
   }
   .info_block_0 {
      min-width: 270px;
   }

   .info_block_1 {
      min-width: 111px;
   }

   .info_block_2 {
      min-width: 82px;
   }

   .info_block_3 {
      min-width: 111px;
   }
   .last_footer_row {
      padding: 0;
      flex-direction: column-reverse;
      border-top: none;
   }

   .last_row_info {
      width: 100%;
      gap: 28px;
      padding: 16px 12px;
      border-top: 1px solid #333333;
      justify-content: center;
   }

   .social_block {
      max-width: 100%;
      padding: 0 12px 29px 12px;
   }

   .subscribe_box {
      background: none;
      padding: 32px 0;
      width: 100%;
   }

   .input {
      font-size: 16px;
      line-height: 16px;
   }
   input::placeholder {
      font-size: 16px;
      line-height: 16px;
   }
}
