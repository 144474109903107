
.background_section_body_faq {
   width: 1200px;
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 40px;
}

.sss_title {
   font-style: normal;
   font-weight: 700;
   font-size: 28px;
   line-height: 36px;
   text-align: center;
   color: #1e1b2e;
}
.questions_block {
   width: 100%;
   max-width: 1000px;
   display: flex;
   flex-direction: column;
   background: #ffffff;
   box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
   border-radius: 4px;
   margin-bottom: 40px;
}

.question_item {
   width: 100%;
   padding: 12px 24px;
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 24px;
   cursor: pointer;
}
.question_item .icon_div {
   cursor: pointer;
   min-width: 48px;
   max-width: 48px;
   min-height: 48px;
   max-height: 48px;
}
.icon_div svg {
   width: 100%;
   height: 100%;
   cursor: pointer;
}

.question_answer {
   width: 100%;
   display: flex;
   flex-direction: column;
   border-bottom: 1px solid #d8d8d8;
}

.question {
   font-style: normal;
   font-weight: 700;
   font-size: 16px;
   line-height: 24px;
   color: #1e1b2e;
}

.answer {
   padding: 0 40px 24px 104px;
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
   color: #333333;
}

@media only screen and (max-width: 1200px) {
   .background_section_body_faq {
      width: calc(100vw - 24px);
   }
}

@media only screen and (max-width: 992px) {
   .background_section_body_faq {
      width: calc(100vw - 24px);
   }
   .sss_title {
      font-size: 20px;
      line-height: 30px;
   }
   .question_item {
      padding: 12px 8px;
      gap: 8px;
   }
   .question {
      font-size: 14px;
      line-height: 24px;
   }
   .answer {
      padding: 0 8px 12px 64px;
   }
}
