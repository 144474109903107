.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -6;
}

.banner_message {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 30px;
}

.copy_banner_message {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
}

.copy_banner_message span{
  font-weight: 700;
}

.banner_icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.copy_btn {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 30px;
}

.copy_popup {
  position: relative;
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 1.5s;
  animation-name: fadeIn;
  animation-duration: 1.5s;
  z-index: 2;
}

.copy_popup img {
  position: absolute;
  top: 12px;
  left: -48px;
  max-width: none;
  transform: rotate(180deg);
}

.copy_popup_message {
  position: absolute;
  top: 20px;
  left: -154px;
  background: #1E1B2E;

  width: 232px;
  height: 36px;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
}

.copy_popup_message p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #FFFFFF;
  text-align: center;
}


@media only screen and (max-width: 770px) {
  .copy_popup {
    position: fixed;
    top: 36px;
    left: 60%;
  }

  .banner_icon {
    margin-left: 16px;
  }

  .copy_btn {
    margin-right: 48px;
  }
}

@media only screen and (max-width: 500px) {
  .alert_icon {
    margin-left: 17px;
  }
  
  .copy_banner_message {
    margin-left: 55px;
  }

  .copy_btn {
    position: absolute;
    left: 15px;
  }

  .copy_popup {
    top: 64px;
  }

  .copy_banner_icon {
    display: none;
  }
}