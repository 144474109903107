@keyframes slidein_first {
  from {
    bottom: 0;
  }

  to {
    bottom: -800px;
  }
}

@keyframes slidein_second {
  from {
    bottom: 0;
  }

  to {
    bottom: -160px;
  }
}

@keyframes slidein_three {
  from {
    bottom: 0;
  }

  to {
    bottom: -480px;
  }
}

@keyframes slidein_foo {
  from {
    bottom: 0;
  }

  to {
    bottom: -640px;
  }
}

@keyframes slidein_five {
  from {
    bottom: 0;
  }

  to {
    bottom: -1120px;
  }
}

@keyframes slidein_six {
  from {
    bottom: 0;
  }

  to {
    bottom: -480px;
  }
}

@keyframes slidein_seven {
  from {
    bottom: 0;
  }

  to {
    bottom: -1440px;
  }
}

.section_card_background {
  width: 100%;
  height: 534px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-image: url("../../../images/Map_full.webp");
  background-size: 1143px 534px;
  background-repeat: no-repeat;
  background-position: top 0 right -379px;
}

.section_no_background {
  width: 1200px;
  position: relative;
}

.numbers_block {
  width: 1028px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 120px;
}

.sub_block {
  /* width: calc(50% - 70px); */
  height: 100px;
  display: flex;
  flex-direction: row;
  gap: 120px;
}

.second_item {
  margin-left: 0;
}

.item_block {
  display: flex;
  flex-direction: column;
}

.item_text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #333333;
  white-space: nowrap;
}

.number_block {
  display: flex;
  flex-direction: row;
}

.item {
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 80px;
  color: #1e1b2e;
}

.num {
  position: relative;
  overflow: hidden;
  height: 80px;
  width: 53px;
}

.roll_second {
  position: absolute;
  width: 53px;
  height: 880px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  animation-duration: 3s;
  animation-name: slidein_first;
}

.roll_first {
  position: absolute;
  width: 53px;
  height: 240px;
  display: flex;
  flex-direction: column;
  animation-duration: 3s;
  animation-name: slidein_second;
}

.roll_three {
  position: absolute;
  width: 53px;
  height: 560px;
  display: flex;
  flex-direction: column;
  animation-duration: 3s;
  animation-name: slidein_three;
}

.roll_foo {
  position: absolute;
  width: 53px;
  height: 720px;
  display: flex;
  flex-direction: column;
  animation-duration: 3s;
  animation-name: slidein_foo;
}

.roll_five {
  position: absolute;
  width: 53px;
  height: 1200px;
  display: flex;
  flex-direction: column;
  animation-duration: 3s;
  animation-name: slidein_five;
}

.roll_six {
  position: absolute;
  width: 53px;
  height: 560px;
  display: flex;
  flex-direction: column;
  animation-duration: 3s;
  animation-name: slidein_six;
}

.roll_seven {
  position: absolute;
  width: 53px;
  height: 1520px;
  display: flex;
  flex-direction: column;
  animation-duration: 3s;
  animation-name: slidein_seven;
}

.three_section_margin {
  margin-top: 104px;
  margin-bottom: 104px;
}

.body_card_section {
  margin-left: 104px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.inf_block_in_body {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 431px;
}

.big_size_inf_row {
  width: 848px;
  display: flex;
  flex-direction: row;
  gap: 80px;
}


.sub_block {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.item_in_sub_block {
  display: flex;
  flex-direction: column;
}


.title_item {
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 80px;
  color: #1e1b2e;
}

.description_item {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #333333;
}

.text_story {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: rgb(51, 51, 51);
}

.title_two_type {
  width: 328px;
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: #1e1b2e;
}

@media only screen and (max-width: 1200px) {
  .section_no_background {
     width: calc(100vw - 24px);
  }
}

@media only screen and (max-width: 992px) {
  .section_card_background {
    height: 846px;
    align-items: flex-start;
    background-size: 735px 344px;
    background-position: bottom 0 center;
  }
  .section_no_background {
    width: 100vw;
 }
  .three_section_margin {
    margin-bottom: 0px;
  }
  .body_card_section {
    margin-left: 0;
    width: 100%;
    align-items: center;
    gap: 24px;
  }
  .inf_block_in_body {
    width: 342px;
  }
  .big_size_inf_row {
    width: 343px;
    flex-direction: column;
    gap: 24px;
  }
  .sub_block {
    gap: 0;
  }
  .item_in_sub_block {
    width: 50%;
  }
  .title_item {
    font-size: 60px;
    line-height: 80px;
  }
  .title_two_type {
    font-size: 20px;
    line-height: 30px;
  }
  .numbers_block {
    width: 343px;
    flex-direction: column;
    height: 224px;
    gap: 24px;
  }
  .sub_block {
    width: 100%;
    justify-content: flex-start;
    gap: 30px;
  }
  .second_item {
    margin-left: 25px;
  }
  .item {
    font-size: 60px;
  }
  .num,
  .roll_first,
  .roll_second,
  .roll_three,
  .roll_foo,
  .roll_five,
  .roll_six,
  .roll_seven {
    width: 37px;
  }
}

@media only screen and (max-width: 610px) {
  .section_card_background {
    background-position: bottom 0 left -63px;
  }
}
