.section_no_background {
   width: 1200px;
   position: relative;
   margin: auto;
}

.top_banner {
   background-color: #f8d210;
   background-image: url('../../../images/bar-chart_1.png'),
      url('../../../images/pattern-lock_1.png'),
      url('../../../images/pie-chart_1.png');
   background-size: 103px 103px, 135px 135px, 157px 157px;
   background-repeat: no-repeat, no-repeat, no-repeat;
   background-position: top 49px left 537px, bottom 50px left 447px, top 70px right 197px;
   margin-top: 120px;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   padding: 0 0 0 101px;
   width: 100%;
}

.banner_info {
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: 24px;
}

.banner_text {
   display: flex;
   flex-direction: column;
   gap: 8px;
   max-width: 408px;
}
.banner_title {
   font-style: normal;
   font-weight: 700;
   font-size: 50px;
   line-height: 60px;
   color: #000000;
}

.banner_message {
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
   color: #000000;
}

.banner_photo {
   margin-top: 13px;
}
.banner_photo img {
   /* margin-bottom: -7px; */
}

@media only screen and (max-width: 1200px) {
   .section_no_background {
      width: calc(100vw - 24px);
   }
   .banner_photo {
      overflow: hidden;
      width: 100%;
      height: 100%;
      text-align: center;
   }
   .banner_photo img {
      min-width: 493px;
      min-height: 357px;
   }
}

@media only screen and (max-width: 992px) {
   .section_no_background {
      width: 100vw;
   }
   .top_banner {
      background-image: url('../../../images/bar-chart_1.png'),
      url('../../../images/pattern-lock_1.png'),
      url('../../../images/pie-chart_1.png');
      background-size: 68px 68px, 89px 89px, 99px 99px;
      background-position: bottom 299px left 64px, bottom 103px left 5px, bottom 293px right 5px;

      padding: 0;
      flex-direction: column;
      align-items: center;
      margin-top: 70px;
   }
   .banner_info {
    width: 343px;
      padding: 64px 0 27px 0;
   }

   .banner_title {
      font-size: 35px;
      line-height: 42px;
   }
   .banner_message {
      font-size: 14px;
      line-height: 24px;
   }
   .banner_photo {
      width: 100%;
      height: 357px;
      background-image: url('../../../images/hero-image.png');
      background-size: 493px 357px;
      background-repeat: no-repeat;
      background-position: center;
   }
   .banner_photo img {
      display: none;
   }
}
