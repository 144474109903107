.section_no_background {
  width: 1200px;
  position: relative;
  margin: auto;
}
.body_section_no_background {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}
.section_title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: #1e1b2e;
}
.tools_body {
  width: 852px;
  height: fit-content;
  background: #ffffff;
  box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
  border-radius: 4px;
  padding: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.tools_item {
  width: 163px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tools_item_last{
   width: 163px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1200px) {
  .section_no_background {
    width: calc(100vw - 24px);
  }
}

@media only screen and (max-width: 992px) {
  .section_no_background {
    width: 100vw;
  }
  .body_section_no_background {
    width: 343px;
    margin-left: calc(50vw - 172px);
    gap: 32px;
  }
  .section_title {
    font-size: 20px;
    line-height: 30px;
  }
  .tools_body {
    width: 362px;
    padding: 30px 23px 19px 23px;
    height: 392px;
    grid-template-columns: repeat(2, 1fr);
  }

  .tools_item {
    width: 100%;
    height: auto;
    margin: 0 auto;
  }

  .tools_item img {
    /* width: 100%; */
    height: auto;
  }

 .tools_item_last{
  width: 100%;
    height: auto;
    margin: 0 auto;
 }

 .tools_item_last img {
     width: 100%;
    height: 90px;
 }
}
