.section_no_background {
  display: flex;
  flex-direction: column;
  max-width: 75rem;
  width: 86%;
  gap: 3rem;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
}
.body_section_no_background {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  position: relative;
}
.student_success_stories {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
  margin-bottom: 2rem;
}
.course_line_view_port {
  width: 100%;
  overflow: hidden;
  -ms-overflow-style: none;
}
.course_line_view_port::-webkit-scrollbar {
  display: none;
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.course_line {
  /* display: flex; */
  flex-direction: row;
  position: relative;
  height: 285px;
  align-items: center;
  margin: 0rem 2.5rem;
}

.centered_mobile {
  display: flex;
  justify-content: center;
  align-items: center;
}



.week_parent_block {
  display: flex !important;
  width: unset !important;
  padding-bottom: 9.5rem !important;
  padding-top: 10rem !important;
}
.parent_week_order,
.week_parent_block {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  gap: 0;
}
.week_item {
  position: relative;
}
.label_match {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 157px;
  right: 0px;
  left: 0px;
  margin: auto;
  bottom: 51px;
}
.label_match .match {
  order: 2;
  color: #d8d8d8;
}

.match_up {
  bottom: 51px;
}

.match_down {
  top: 56px;
}

.custom_icon {
  width: 8px;
  height: 32px;
}
.week_title {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #333;
  opacity: 0.8;
  order: 1;
}

.week_style {
  display: flex;
  flex-direction: column;
}
.slide_header {
  width: 100%;
  padding: 40px 35px 24px 40px;
  border-bottom: 1px solid #d8d8d8;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.right_section {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.week_title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: #1e1b2e;
}
.tag {
  padding: 8px 16px;
  margin-top: 0.5rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #000;
}

.tag span {
  font-weight: 700;
}
.slide_body {
  padding: 40px;
  display: flex;
  flex-direction: row;
}
.description_section {
  width: 67%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.description {
  width: 100%;
  max-width: 530px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #333;
}
.points_section {
  width: 33%;
  display: flex;
  flex-direction: column;
}
.point_item_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}
.in_point_item_row {
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  padding-bottom: 8px;
  gap: 16px;
  align-items: center;
  z-index: 2;
}
.point_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: #fef6cf;
  box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
  border-radius: 100%;
}
.disk_dot {
  width: 8px;
  height: 8px;
  background: #f8d210;
  border-radius: 100%;
}
.point_text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: rgba(248, 210, 16, 0.2);
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000;
}
.skills_parent {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.skills_title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #333;
}
.skills {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}
.skill_item {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #000;
}
.vertical_line {
  position: absolute;
  height: 100%;
  border: 1px solid #d8d8d8;
  z-index: 1;
  left: 10px;
}
.first {
  height: 50%;
  top: 50%;
}
.last {
  height: 50%;
  bottom: 50%;
}
.arrowNext {
  font-size: 2rem;
  display: none;
}

.prevArrow {
  font-size: 2rem;
  display: none;
}

.sss_carousel_row {
  width: 100%;
  overflow: hidden;
  position: relative;
  flex-direction: row;
  box-shadow: 1px 3px 20px rgb(1 1 1 / 7%);
  border-radius: 4px;
  height: fit-content;
}
.pagination {
  position: absolute;
    margin: 0 auto;
    width: 100%;
    align-items: center;
    cursor: pointer;
    bottom: -16px;
    height: 36px;
}

.barContainer {
  height: 6px;
  background-color: #ddd;
  border-radius: 2px;
  overflow: hidden;
  margin: 16px 0;
  position: relative;
}

.bar {
  height: 100%;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 33.33%;
  transition: width 0.5s ease-in-out;
}

.top_stroke {
  border-bottom: 2px solid #d8d8d8;
  width: 1036px;
  height: 0px;
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: center;
  z-index: 0;
}
.top_stroke > button {
  flex: none;
  order: 0;
  flex-grow: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px;
  width: 107px;
  height: 56px;
  position: relative;
  z-index: 99;
  background-color: #fff;
}
.top_stroke > button:focus {
  background: #f8d210;
}
.long_stroke {
  border: 1px solid #d8d8d8;
  width: 1200px;
  height: 0px;
  display: flex;
}
.top_margin {
  margin-top: 3.5rem;
  margin-left: 2.5rem;
}
.year_title {
  color: #1e1b2e;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  width: 210px;
  height: 36px;
  margin-bottom: 2rem;
}
.first_desc {
  color: #333333;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  width: 530px;
}
.desc_wrapper {
  margin-top: 4rem;
  margin-left: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.content_container {
  display: flex;
  gap: 3rem;
}
.content_right {
  position: relative;
  width: 563px;
  height: 407px;
  bottom: -58px;
}
.content_img {
  width: 563px;
}
.second_desc {
  width: 530px;
  height: 84px;
  color: #333333;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.flex_btn_left {
  position: absolute;
  left: -60px;
  bottom: 280px;
}
.flex_btn_right {
  position: absolute;
  right: -60px;
  bottom: 280px;
}

.flex_btn_left2 {
  position: absolute;
  left: -30px;
  bottom: 83px
}

.flex_btn_right2 {
  position: absolute;
  right: -34px;
  bottom: 83px
}
.active {
  background-color: #f8d210 !important;
}

.week_active {
  color: #f8d210;
}


.os {
  /* width: 44px !important; */
  border: 1px solid #d8d8d8 !important;
}

.week_order {
  transition: all 0.8s;
  width: 104px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  /* padding: 16px 24px; */
  background: #ffffff;
  box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
  border-radius: 4px;
  border: 1px solid #ffffff;
  cursor: pointer;
}
.week_order:hover {
  transition: all 0.8s;
  border: 1px solid #d8d8d8;
}

.week_active_background {
  background: #f8d210;
  border: 1px solid #f8d210;
  cursor: default;
}
.week_active_background:hover {
  border: 1px solid #f8d210;
}

.week_title {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #333333;
  opacity: 0.8;
}

.week_title1 {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: #1e1b2e;
}

@media only screen and (max-width: 992px) {
  .section_no_background {
    /* width: 92%; */
    gap: 0rem;
  }

  .sss_carousel_row {
    width: 85%;
    margin: auto;
    justify-content: center;
  }
  .top_stroke {
    border-bottom: 2px solid #d8d8d8;
    width: 13.1875rem;
    height: 0px;
    display: flex;
    gap: 9px;
    align-items: center;
    justify-content: center;
    z-index: 0;
    max-width: 13.1875rem;
  }
  .top_stroke > button {
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;
    width: 108px;
    height: 56px;
    position: relative;
    z-index: 99;
    background-color: #fff;
  }
  .flex_btn_right {
    position: absolute;
    right: -9px;
    top: -11px;
  }
  .flex_btn_left {
    position: absolute;
    left: -9px;
    top: -11px;
  }
  .flex_btn_left2,
  .flex_btn_right2 {
    display: none !important;
  }

  .slide_header {
    flex-direction: column;
    padding: 22px 16px 16px 16px;
    align-items: flex-start;
  }
  .slide_column {
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .slide_body {
    flex-direction: column;
    gap: 40px;
    padding: 16px;
  }
  .description_section {
    width: 100%;
    max-width: 697px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #333;
  }
  .student_success_stories {
    width: 114%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
    margin-bottom: 0.3rem;
  }
  .sss_carousel_row {
    overflow: hidden;
    position: relative;
    flex-direction: row;
    box-shadow: 1px 3px 20px rgb(1 1 1 / 7%);
    border-radius: 4px;
    height: fit-content;
    padding: 0.3rem;
  }
  .points_section {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .content_container {
    margin-left: 0.5rem;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    overflow: hidden;
    align-items: center;
  }
  .desc_wrapper {
    margin-top: 0.3rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
  .first_desc {
    padding: 3px;
    position: relative;
    left: -33px;
    color: #333333;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    width: fit-content;
  }
  .second_desc {
    padding: 3px;
    position: relative;
    width: fit-content;
    right: 34px;
    height: 84px;
    color: #333333;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }
  .course_line {
    display: grid;
    flex-direction: row;
    position: relative;
    height: 105px;
    align-items: center;
    margin: 0rem;
    width: 107%;
  }
  .content_right {
    position: relative;
    width: 400px;
    height: 334px;
    right: 6px;
    bottom: -39px;
  }
  .pagination {
        position: absolute;
    margin: 0 auto;
    width: 100%;
    align-items: center;
    cursor: pointer;
    bottom: -43px;
    height: 36px;
  }
  .os {
    display: none;
  }
  .week_order {
    white-space: nowrap;
  }

  .week_order:hover {
    transition: all 0.8s;
    border: 1px solid #ffffff;
  }
  .week_title {
    display: none;
  }
  .label_match {
    display: none;
  }
  .week_parent_block {
    margin-right: 8px;
  }
}

@media only screen and (max-width: 820px) {
  .sss_carousel_row {
    overflow: hidden;
    position: relative;
    flex-direction: row;
    box-shadow: 1px 3px 20px rgb(1 1 1 / 7%);
    border-radius: 4px;
    height: fit-content;
    padding: 0.3rem;
    width: 89%;
  }
  .desc_wrapper {
    display: flex;
    margin-top: 0.3rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    justify-content: center;
    align-items: center;
  }
  .first_desc {
    padding: 3px;
    position: relative;
    left: -33px;
    color: #333333;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    width: 633px;
  }
  .second_desc {
    padding: 3px;
    position: relative;
    width: 633px;
    right: 34px;
    height: 84px;
    color: #333333;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }
  .content_right {
    position: relative;
    width: 540px;
    height: 443px;
    right: -3px;
    bottom: -24px;
  }
}

@media only screen and (max-width: 540px) {
  .section_no_background {
    width: 92%
  }
  .first_desc {
    padding: 3px;
    position: relative;
    left: -33px;
    color: #333333;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    width: 447px;
  }
  .second_desc {
    padding: 3px;
    position: relative;
    width: 447px;
    right: 34px;
    height: 84px;
    color: #333333;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }
  .content_right {
    position: relative;
    width: 518px;
    height: 443px;
    right: 11px;
    bottom: -43px;
  }
}

@media only screen and (max-width: 500px) {
  .sss_carousel_row {
    overflow: hidden;
    position: relative;
    flex-direction: row;
    box-shadow: 1px 3px 20px rgb(1 1 1 / 7%);
    border-radius: 4px;
    height: fit-content;
    padding: 0.3rem;
  }
  .first_desc {
    padding: 3px;
    position: relative;
    /* left: -16px; */
    color: #333333;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    width: fit-content;
  }
  .second_desc {
    padding: 3px;
    position: relative;
    width: fit-content;
    /* right: 20px; */
    height: 84px;
    color: #333333;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }
  .content_right {
    position: relative;
    width: 343px;
    height: 347px;
    right: -2px;
    bottom: -63px;
  }
}