.container{
    font-size: 14px;
    font-weight: 500;
    font-family: inherit;
    border-radius: 4px;
    margin-bottom: 4%;
    margin-left: 25%;
    max-width: 45%;                           
}

.main{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.text{
    margin-left: 1rem;
}

.actionbtn{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.buttons{
    display: flex;
    gap:1rem;
}

.managebtn{
    text-decoration: underline;
}

.acceptbtn{                 
    width: 165.5px;
    height: 30px;   
}
.declinebtn{
    width: 165.5px;
    height: 30px;
}

@media only screen and (max-width:1200px){
    .container{
        max-width:70%;
        margin: 0 1rem 6rem 8rem;
    }
 }
@media only screen and (max-width: 768px) {  
    .container{
    margin:0 1% 6rem 1%;
    max-width: 98%;   
    }
    
.main{
    gap:1rem;
}
    .actionbtn{   
        display: flex;    
        flex-direction: column;
        gap:1rem;
     }     

    .declinebtn{
        width: 151px;
        height: 30px;
    }
    .acceptbtn{
        width: 151px;
        height: 30px;
    }
 }

 