a {
  text-decoration: none;
}

.slide_div {
  width: 312px !important;
  height: 362px;
  background-color: #ffffff;
  border-radius: 5px 5px 4px 4px;
  overflow: hidden;
  transition: all 0.8s;
}
.slide_div:hover {
  transition: all 0.8s;
}
.slide_div:hover img {
  transition: all 0.8s;
  transform: scale(1.05);
}
.slide_div .item {
  width: 100%;
  height: 100%;
  transition: 0.8s;
}
.slide_div .img_body {
  width: 100%;
  height: 134px;
  object-fit: cover;
}
.slide_div .img_body img {
  transition: all 0.8s;
}

.img_body img {
  width: 100%;
  height : 134px;
  overflow: hidden;
}

.slide_info {
  width: 312px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px 32px;
  gap: 8px;
  background: #ffffff;
  border-radius: 0 0 4px 4px;
}
.slide_info .time {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #333333;
  margin-top: 0.5rem;
}
.slide_title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1e1b2e;
  align-self: stretch;
}
.slide_title_a {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1e1b2e;
  align-self: stretch;
  transition: all 0.8s;
}
.slide_title_a:hover {
  transition: all 0.8s;
  transform: scale(1.05);
}
.slide_text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
  align-self: stretch;
}
.carousel_body {
  width: 81%;
  max-width: 968px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.paginate {
  position: absolute;
  top: 386px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0;
}

.paginate_item_block {
  /* width: calc(100% / 3); */
  width: 100%;
  height: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.paginate_item {
  transition: 0.8s;
  width: 100%;
  height: 4px;
  background-color: #ffffff;
  cursor: pointer;
}
.active {
  transition: 0.8s;
  background-color: black;
}
.barContainer {
  width: 100%;
  height: 4px;
  background-color: #fff;
  border-radius: 2px;
  overflow: hidden;
  margin: 16px 0;
  position: relative;
}

.bar {
  height: 100%;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 33.33%;
  transition: width 0.5s ease-in-out;
}
.hidden_over {
  overflow: hidden;
}
.view_port {
  display: flex;
  flex-direction: row;
  gap: 16px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}
.body_items {
  width: 100%;
  gap: 16px;
}
.body_items2 {
  width: 100%;
  display: flex;
  gap : 16px;
}
.body_items .active {
  transition: 0.8s;
  opacity: 1;
}
.arrow_section {
  position: absolute;
}
.left_section {
  left: -64px;
  top:25%;
}
.right_section {
  right: -64px;
  top:25%;
}
.circle_arrow_btn {
  width: 48px;
  height: 48px;
  border-radius: 100px;
  border: none;
  background-color: #f8d210;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: black;
  overflow: hidden;
}
.bg_white {
  background-color: #ffffff;
}

@media only screen and (max-width: 1200px) {
  .body_items2 {
    gap: 0px;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 992px) {
  .paginate {
    width: calc(100% - 152px);
    left: 76px;
    top: 396px;
  }
  .view_port {
    overflow: scroll;
  }
  .view_port::-webkit-scrollbar {
    display: none;
    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
  }
  .view_port:hover {
    /* filter: drop-shadow(1px 3px 20px rgba(1, 1, 1, 0.12)); */
  }
  .carousel_body {
    width: 100%;
  }
  .slide_div {
    width: 340px !important;
  }
  .slide_div .img_body {
    width: 100%;
  }
  .slide_div img {
    width: 100%;
  }
  .slide_info {
    width: 100%;
  }
  .arrow_section {
    top: 380px;
  }
  .right_section {
  right: 0px;
}
.left_section {
  left: 0px;
}
}
