.modal-shadow {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.39);
  z-index: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  overflow: hidden;
  overscroll-behavior: unset;
}

.inner {
  position: relative;
  background: black;
  color: #fff;
  padding: 2rem;
  bottom: 21px;
}

.top_btn {
  float: right;
}

.modal_body {
  gap: 4rem;
  margin: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top_span {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 4px;
  width: fit-content;
  padding: 8px 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_body_left {
  max-width: 480px;
  height: 416px;
}

.modal_header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  margin-top: 1rem;
}

.sub_header {
  color: #f8d210;
}

.modal_desc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 1rem;
}

.modal_list {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.modal_list>img {
  width: 16px;
  height: 11.99px;
}

.modal_body_right {
  background: rgba(255, 255, 255, 0.1);
  width: 484px;
  padding: 30px 25px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal_body_right>div {
  margin-bottom: 1rem;
}

.name_input {
  display: flex;
  gap: 10px;
  margin-bottom: 1rem;
}

.contry_drop {
  width: 98%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: relative;
  bottom: 50px;
  right: -11px;
}

.phone_label {
  position: relative;
  bottom: -8px;
}

.contry_border {
  position: relative;
  width: 14%;
  height: 44px;
  padding: 16px;
  border: 2px solid #ffffff;
  border-radius: 4px;
  background-color: black;
  color: #ffffff;
  bottom: -10px;
  left: -4px;
}

select {
  background: black;
  color: #fff;
}

.flex {
  position: relative;
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  top: -45px;
}

.check_box {
  border-radius: 2px;
  width: 18px;
  height: 18px;
}

.check_text {
  color: #d8d8d8;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  height: 60px;
  left: -13px;
  line-height: 17px;
  margin-bottom: 1rem;
  position: relative;
  width: 394px;
}

.submit_btn {
  position: relative;
  top: -54px;
  align-items: center;
  background: #f8d210;
  border-radius: 4px;
  color: #000;
  display: flex;
  flex-direction: row;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  gap: 16px;
  height: 46px;
  justify-content: center;
  line-height: 14px;
  padding: 16px 32px;
  transition-duration: 0.5s;
  width: 420px;
}

.submit_btn:hover {
  font-weight: 700;
}

.check_box input[type="checkbox"] {
  display: none;
}

.check_box .checkmark {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
}

.check_box .checkmark::before {
  content: '';
  display: block;
  position: absolute;
  top: 20%;
  left: 50%;
  width: 67%;
  height: 20%;
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg) translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.check_box input[type="checkbox"]:checked+.checkmark::before {
  opacity: 1;
}



@media only screen and (max-width: 992px) {
  .modal_body {
    display: flex;
    flex-direction: column;
    margin: 12px;
  }

  .inner {
    background: #000;
    bottom: 10px;
    color: #fff;
    height: 100vh;
    margin: 0 auto;
    max-width: 100%;
    overflow-y: auto;
    padding: 1rem;
    position: relative;
    top: 27px;
  }

  .modal_header {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    margin-top: 8px;
    width: 269px;
    height: 110.32px;
  }

  .modal_desc {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 8px;
    width: 311px;
    height: 49.06px;
  }

  .name_input {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
  }

  .modal_body_left {
    max-width: 461px;
    height: 200px;
  }

  .top_span {
    background: rgba(255, 255, 255, 0.15);
    border-radius: 4px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 8px 16px;
    font-family: "Poppins";
    font-style: normal;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal_list {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    gap: 8px;
    width: 287px;
    align-items: baseline;
  }

  .modal_body_right {
    position: relative;
    background: rgba(255, 255, 255, 0.1);
    width: 484px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 550px;
    max-width: 390px;
    top: -72px;
  }

  .submit_btn {
    position: relative;
    top: -32px;
    right: -20px;
    background: #f8d210;
    border-radius: 4px;
    display: flex;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    gap: 16px;
    width: 311px;
    height: 46px;
    color: black;
    transition-duration: 0.5s;
  }

  input {
    background-color: #000;
    border: 2px solid #fff;
    border-radius: 4px;
    color: #fff;
    height: 44px;
    padding: 17px;
    width: 100%;
    font-family: 'Poppins';
  }

  label {
    color: #fcfcf5;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .contry_border {
    border: 2px solid #fff;
    border-radius: 4px;
    color: #fff;
    height: 44px;
    position: relative;
    bottom: -11px;
    width: 17%;
    padding: 6px;
  }

  .contry_border,
  .phone-input {
    border: 2px solid #fff;
    border-radius: 4px;
    color: #fff;
    height: 44px;
    position: relative;
    bottom: -11px;
    width: 17%;
    padding: 6px;
  }

  .contry_drop {
    position: relative;
    bottom: 49px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .flex {
    display: flex;
    margin-top: 1rem;
    gap: 6px;
    justify-content: space-around;
  }

  .check_text {
    color: #d8d8d8;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    width: 285px;
    height: 80px;
    margin-bottom: 1px;
  }
}