.dropdown_body {
   position: relative;
}

.positioning {
   position: absolute;
   top: 0;
   right: 0;
   left: auto;
   z-index: 100;
   overflow: auto;
}

.hidden {
   height: 30px;
}

.hidden>* {
   display: none;
}

.list_body {
   background: black;
   display: flex;
   padding: 40px;
   gap: 80px;
}

.long_list {
   flex-wrap: wrap;
   width: 1200px;
}

.list_el {
   width: 219px;
   color: #ffffff;
   display: flex;
   flex-direction: column;
   padding: 1px;
}

.list_el h1 {
   margin-bottom: 8px;
}

.list_el h1 span {
   display: flex;
   gap: 8px;
   font-family: Poppins;
   font-size: 16px;
   font-style: normal;
   font-weight: 700;
   line-height: 24px;
   align-items: center;
}

.list_el p {
   width: 100%;
   font-family: Poppins;
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px;
   /* 166.667% */
   opacity: 0.6;
   margin-bottom: 24px;
}

.list_el div {
   display: flex;
   flex-direction: column;
   gap: 16px;
}

.see_all {
   display: flex;
   padding: 24px;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 24px;
   align-self: stretch;
   border-radius: 4px;
   background: rgba(255, 255, 255, 0.08);
   color: white;
   /* Shadow */
   box-shadow: 1px 3px 20px 0px rgba(1, 1, 1, 0.07);
}

.link {
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 14px;
   gap: 16px;
}

.see_all,
.link {
   cursor: pointer;
}

.link:hover,
.see_all:hover {
   color: #F8D210;
}

@media only screen and (max-width: 1200px) {
   .long_list {
      width: 900px;
   }
}

@media only screen and (max-width: 992px) {
   .dropdown_body {
      width: 316px;
      position: inherit;
   }

   .positioning{
      position: inherit;
   }

   .hidden {
      height: 72px;
      color: #ffffff;
      background: black;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 12px 16px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
   }

   .hidden>* {
      display: block;
   }

   .hidden h1 {
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 166.667% */
   }

   .button_parent {
      width: 100%;
   }

   .nav_title {
      width: 100%;
   }

   .nav_title svg {
      transform: rotate(-90deg);
   }

   .list_body {
      width: 100%;
      gap: 0px;
      padding: 0px;
      flex-direction: column;
   }

   .list_el {
      width: 100%;
      padding: 24px 16px 24px 24px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
   }

   .list_el h1 {
      display: flex;
      justify-content: space-between;
   }

   .list_el h1 span {
      display: flex;
      align-items: center;
      justify-content: center;
   }

   .list_el p {
      margin-bottom: 16px;
   }

   .arrow_down {
      rotate: 180deg;
   }

   .see_all {
      width: 100%;
      margin: 16px 16px 6px 21px ;
   }
}