.section_first_background {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 590px;
  margin: auto;
  margin-top: 40px;
  background-color: #fcfcf5;
  background-image: url("../../../images/bg/Rectangle873.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% 589px;
}

.background_section_body {
  width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.card_row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.card_item {
  width: 295px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 8px;
  background: #ffffff;
  box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
  border-radius: 4px;
}
.card_title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1e1b2e;
}

.card_text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}
@media only screen and (max-width: 1200px) {
  .background_section_body {
    width: calc(100vw - 24px);
  }
}

@media only screen and (max-width: 992px) {
  .section_first_background {
    height: 100%;
    margin-top: 200px;
    background-size: 100% 100%;
    padding-bottom: 40px;
  }
  .card_row {
    flex-direction: column;
    gap: 24px;
  }
  .card_item {
    width: 343px;
    height: 100%;
  }
}
