.section_no_background {
  width: 1200px;
  position: relative;
}

.body_section_no_background {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}

.body_section_no_background>section:first-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.sss_title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #1e1b2e;
}

.region_select_section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.region_select_section span {
  width: 160px;
  height: 46px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;

  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;

  border-top: 2px solid #fff;
  border-bottom: 2px solid #d8d8d8;

  cursor: pointer;
}

.region_select_section>span:hover {
  font-size: 15px;
  line-height: 15px;
}

.selected_region {
  font-weight: 600 !important;
  border-top: 3px solid #fff !important;
  border-bottom: 3px solid #f8d210 !important;
}

.program_dates {
  width: 1070px;
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.program_date_point {
  width: 100%;
  border: 2px solid #d8d8d8;
  border-radius: 4px;
  padding: 24px 40px 16px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 100px;
}

.program_info_block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.program_info_items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  gap: 0px;
}

.program_info_items:first-child {
  width: 325px;
  gap: 4px;
}

.program_info_title {
     font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #333333;
    display: flex;
    justify-content: center;

}

.program_info_title span {
  font-weight: 600;
}

.program_info_period {
  font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    color: #1e1b2e;
    width: max-content;
}

.program_info_sessions {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #333333;
  padding: 9px;
}

.program_button_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  gap: 4px;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #1e1b2e;
}

.select_cont {
  display: none;
}
@media only screen and (min-width: 1440px) {
   .program_dates {
  width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 22px;
}

}

@media only screen and (max-width: 1200px) {
  .section_no_background {
    width: calc(100vw - 24px);
  }
  .program_date_point {
    gap: 40px;
  }
 
}



@media only screen and (max-width: 992px) {
  .section_no_background {
    width: 100vw;
  }

  .body_section_no_background {
    width: 343px;
    margin-left: calc(50vw - 172px);
    gap: 32px;
  }

  .sss_title {
    font-size: 20px;
    line-height: 30px;
  }

  .program_date_point {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
  }

  .region_select_section {
    display: none;
  }

  .program_info_block {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .program_btn {
    padding: 16px 32px !important;
  }

  .title {
    font-size: 12px;
    line-height: 12px;
  }

  .body_section_no_background>section:first-child {
    gap: 20px;
  }

  .select_cont {
    width: 12rem;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background: rgba(0, 0, 0, 0.04);
  }

  .arrow {
    width: 12px;
    margin-right: 8px;
  }

  .selected_arrow {
    transform: rotate(180deg);
  }

  .select {
    width: 12rem;
    padding: 8px;
    display: flex;
    flex-grow: 1;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    color: #000;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
  }

  .select span {
    text-align: center;
  }
  .program_dates {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 22px;
}

  .dropdown {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    color: #1E1B2E;
    background: #fff;
    border-radius: 4px 4px 0px 0px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 12px;
    padding-left: 16px;
    color: #000;
  }

  .selected {
    background: #F5F5EF;
  }

  .select_options {
    width: 12rem;
    position: absolute;
    top: 105%;
    background: rgba(0, 0, 0, 0.04);
  }

  .select_dropdown {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
