.section_no_background {
    width: 1200px;
    position: relative;
    margin: auto;
  }
  
  .top_banner {
    background-color: #f8d210;
    background-image: url("../../../images/svg/book.svg"),
      url("../../../images/svg/Bookmark.svg"),
      url("../../../images/svg/notepad.svg");
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: 111px 111px, 74px 74px, 77px 77px;
    background-position: bottom 28px left 245px, top 19px left 716px,
      top 98px left 927px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
    width: 100%;
    height: 292px;
    gap: 8px;
    border-radius: 4px;

   
  }
  
  .inf_block {
    width: 653px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  
  .banner_title {
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 60px;
    color: #000000;
    text-align: center;
  }

  .dot {
    width: 4px;
    height: 4px;
  }

  /* For the Article Page */
  .articleTitle {
    font-size: 35px;
    font-weight: 700;
    line-height: 42px;
    text-align: center;
  }  

  .topBanner {
    background-color: #f8d210;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
    width: 100%;
    height: 214px;
    gap: 24px;
    border-radius: 4px;
    padding: 64px 0px 24px 0px;
  }

  .blockBanner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    gap: 24px;
  }

  .shr_button {
    height: 24px !important;
    width: fit-content;
  }

  .shr_button span {
    flex-direction: row-reverse;
    gap: 8px;
    text-align: left;
  }
  
  @media only screen and (max-width: 1200px) {
    .section_no_background {
      width: calc(100vw - 24px);
    }
  }
  
  @media only screen and (max-width: 992px) {
    .section_no_background {
      width: 100vw;
    }
    .top_banner {
      margin-top: 70px;
      height: 338px;
      background-image: none;
      border-radius: 0;
    }
    .inf_block {
      width: 343px;
    }
    .banner_title {
      font-size: 35px;
      line-height: 42px;
    }
  }
  