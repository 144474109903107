.section_yellow_background {
  width: 100%;
  height: 296px;
  background: linear-gradient(#F8D210 100%, #F8D210 100%) bottom center / 100% 216px no-repeat;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

 @media only screen and (max-width: 992px) {
  .section_yellow_background{
    height: 768px;
    background: linear-gradient(#F8D210 100%, #F8D210 100%) bottom center / 100% 50% no-repeat;
  }
  }

.section_no_background {
  width: 1200px;
  position: relative;
}

  @media only screen and (max-width: 1200px) {
    .section_no_background{

      width: calc(100vw - 24px);
    }
  }

  @media only screen and (max-width: 992px) {
    .section_no_background{

      width: 100vw;
    }
  }

.card_block_row {
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;
}

 @media only screen and (max-width: 992px) {
   .card_block_row{

     flex-direction: column;
     gap: 16px;
     justify-content: space-between;
     align-items: center;
  }
    
  }

.card_in_row {
  width: 384px;
  height: 216px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 8px;
  background: #FFFFFF;
  box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
  border-radius: 4px;
}
@media only screen and (max-width: 992px) {
  .card_in_row{
    width: 344px;
    height: 216px;
  }
  }

.card_in_row_icon {
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
}

 svg {
      width: 100%;
      height: 100%;
    }

    .card_in_row_title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.card_in_row_text {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}
