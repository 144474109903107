.container{
    display: flex;
    width: 1200px;
    margin: 120px auto;
    padding: 32px;
    justify-content: center;
    background: #f8d210;
    background-image: url('../../images/pattern-lock_1.png');      background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: 120px 120px;
    background-position: top 60% left 60%;
}

    .leftDiv{
        margin-top: 74px;
        margin-left: 74px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .heading{
        width: 100%;
        left: 220px;
        top: 212px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 60px;
    }

    .subText{
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
    }

    .points_section {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 1rem 0; 
    }
    
    .point_item_row {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
    }
    
    .in_point_item_row {
        display: flex;
        flex-direction: row;
        padding-top: 8px;
        padding-bottom: 8px;
        gap: 16px;
        align-items: center;
        z-index: 2;
    }
    
    .point_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        background: rgba(255, 255, 255, 0.2);
        box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
        border-radius: 100%;
      }
      .disk_dot {
        width: 8px;
        height: 8px;
        background:#FFFFFF;;
        border-radius: 100%;
      }
      .point_text {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        gap: 10px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        background: #FFFFFF;
        border-radius: 4px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #000;
      }

      .vertical_line {
        position: absolute;
        height: 100%;
        border: 1px solid #FFFFFF;
        z-index: 1;
        left: 11px;
      }

      .first {
        height: 50%;
        top: 50%;
      }
      .last {
        height: 50%;
        bottom: 50%;
      }

    @media only screen and (max-width: 950px){
        .container{
            display: flex;
            flex-direction: column;
            gap: 2rem;
            width: 100%;
            margin: 0;
            padding: 0;            
        }
        .heading{
            width: 343px;
            left: 16px;
            top: 176px;
            padding-top: 2rem;
            line-height: 42px;
        }
      .leftDiv{
        margin-left: 2rem;
      }
    }

    @media only screen and (max-width: 750px){
        .container{
            display: flex;
            flex-direction: column;
            gap: 2rem;
            width: 100%;
            margin: 0;
            padding: 0;            
        }

        .leftDiv{
            margin-left: 1rem;
            margin-right: 1rem;
        }

        .heading{
            width: 343px;
            left: 16px;
            top: 176px;
            padding-top: 2rem;
            font-size: 35px;
            line-height: 42px;
        }
    
        .subText{
            font-size: 18px;
            line-height: 27px;
        }
    }