.description_courses_block {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 8px;
   margin-bottom: 40px;
}
.description_courses_title {
   width: 100%;
   text-align: center;
   font-style: normal;
   font-weight: 700;
   font-size: 28px;
   line-height: 36px;
   color: #1e1b2e;
}

.description_courses_text {
   width: 800px;
   text-align: center;
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 28px;
   color: #333333;
}

.slider_block {
   height: 462px;
   width: 100%;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   position: relative;
}

.justify-end {
  justify-content: flex-end;
}


@media only screen and (max-width: 992px) {
   .courses_row {
      margin-right: -12px;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      padding-top: 0;
   }

   .description_courses_block {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
      margin-bottom: 32px;
   }
   .description_courses_title {
      width: 340px;
      text-align: start;
      font-size: 20px;
      line-height: 30px;
   }

   .description_courses_text {
      width: 340px;
      text-align: start;
   }

   .slider_block {
      height: 462px;
      width: 342px;
      margin-left: calc(50% - 171px);
      padding: 0;
      position: relative;
   }
}
