.bg-right-slope {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fcfcf5;
  background-image: url('../../images/bg/Rectangle846.png');  
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% 463px;
  /* padding-top: 160px; */
}

@media only screen and (max-width: 992px) {
  .bg-right-slope {
    background-image: url('../../images/bg/Rectangle-mobile846.png'); 
    background-size: 100% 620px;
    /* padding-top: 30px; */
  }
}

.bg-left-slope {
  width: 100%;
  min-height: 584px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  background-color: #fcfcf5;
  background-image: url('../../images/bg/rectangle870.png');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% 584px;
}

.bg-left-slope-high {
    width: 100%;
    min-height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    background-color: #fcfcf5;
    background-image: url('../../images/bg/Rectangle836.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 890px;
 }

 @media only screen and (max-width: 992px) {
  .bg-left-slope-high {
     background-size: 100% 750px;
  }
}
