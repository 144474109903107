.section_no_background {
  width: 1200px;
  margin: 3rem auto;
}

@media only screen and (max-width: 1200px) {
  .section_no_background {
    width: calc(100vw - 24px);
  }
}

@media only screen and (max-width: 992px) {
  .section_no_background {
    width: 100vw;
  }
}

.cta_wrapper {
    width: 100%;
    background-image: url('../../images/bg/black_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 64px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    gap: 8px;
}

.title_for_cta {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    color: #fff;
}

.cta_desc {
    max-width: 680px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #fff;
}

.cta_btn{
  width: 200px;
}

.cta_sub{
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
text-align: center;
display: flex;
gap: 1rem;
color: #fff;
}

.cta_sub1{
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 13px;
line-height: 24px;
text-align: center;
display: flex;
gap: 1rem;
color: #fff;
}

.cta_sub_span{
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
text-align: center;
color: #F8D210;
}

.cta_flex{
  display: flex;
  gap: 2rem;
}

@media only screen and (max-width: 992px) {
  .cta_flex{
    display: flex;
    flex-direction: column;
    gap: 2rem;  
  }
  .cta_btn{
  width: 200px; 
}
.cta_sub1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    display: flex;
    gap: 1rem;
    color: #fff;
    width: 331px;
}
.title_for_cta {
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 30px;
}
.cta_desc {
     font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    width: 298px;
    height: fit-content;
    text-align: center;
    margin: 5px;
}
}