.work_logos_section {
  width: 100%;
  padding-bottom: 7rem;
}

.margin_bottom_logos_block {
    margin-bottom: 30px;
}

.sss_title {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    color: #1e1b2e;
}
.first_logos_row {
  height: 66px;
  margin-top: 10px;
  margin-bottom: 10px;
  filter: grayscale(100%);
  background-image: url("../../../images/bg/1_1.png");
      background-position-x: 51px;
}

.second_logos_row {
  height: 66px;
  margin-top: 10px;
  margin-bottom: 10px;
  filter: grayscale(100%);
  background-image: url("../../../images/bg/2_1.png");
  background-position-x: -51px;
}
.third_logos_row {
  display: none;
  height: 66px;
  margin-top: 10px;
  margin-bottom: 10px;
  filter: grayscale(100%);
  background-image: url("../../../images/bg/1_1.png");
}
.fourth_logos_row {
  display: none;
  height: 66px;
  margin-top: 10px;
  margin-bottom: 10px;
  filter: grayscale(100%);
  background-image: url("../../../images/bg/2_1.png");
}
.fifth_logos_row {
  display: none;
  height: 66px;
  margin-top: 10px;
  margin-bottom: 10px;
  filter: grayscale(100%);
  background-image: url("../../../images/bg/1_1.png");
}
.sixth_logos_row {
  display: none;
  height: 66px;
  margin-top: 10px;
  margin-bottom: 10px;
  filter: grayscale(100%);
  background-image: url("../../../images/bg/2_1.png");
}

@media only screen and (max-width: 992px){
  .work_logos_section {
    padding-bottom: 1rem;
  }
  
.sss_title, .start_your_title {
    font-size: 20px;
    line-height: 30px;
}
  .fourth_logos_row, .third_logos_row, .fifth_logos_row, .sixth_logos_row {
      display: block;
          background-position-x: 257.938px;
  }
  
}