.parent_announces {
   width: 100%;
   cursor: pointer;
}

.announces_row {
   width: 100%;
   overflow: hidden;
   background: black;
   height: 76px;
   display: flex;
   align-items: center;
   white-space: nowrap;
}

.announce_item {
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}
.announce_cta {
   color: #f8d210;
   margin-left: 16px;
   text-decoration: none;
}

.elipse_announce {
   width: 8px;
   height: 8px;
   border-radius: 100%;
   background: #f8d210;
   margin-left: 32px;
   margin-right: 32px;
}
