.section_no_background {
   width: 1200px;
   margin: auto;
}

.top_banner {
   background-color: #f8d210;
   background-image: url('../../../images/org.png'), url('../../../images/analytics_1.png'),
   url('../../../images/pie-chart1_yellow.png'), url('../../../images/pie-chart2_yellow.png'),
   url('../../../images/group.png');
   background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
   background-size: 212px 212px, 101px 101px, 146px 146px, 102px 102px, 123px 55px;
   background-position: top 31px left 60%, top 31px right 39px, top 339px left 55%,
      top 170px right 115px, top 297px right 84px;

   display: flex;
   flex-direction: row;
   justify-content: space-between;
   /* padding: 12px 0 0 80px; */
   padding: 12px 0px 0px 104px;
   width: 100%;
   margin-top: 120px;
}

.banner_info {
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: 24px;
}

.banner_photo{
   position: relative;
    top: -5px;
    width: 510px;
    /* height: 406px; */
    align-content: flex-end;
    -webkit-writing-mode: vertical-lr;
}

.banner_text {
   display: flex;
   flex-direction: column;
   gap: 8px;
}
.banner_title {
   font-style: normal;
   font-weight: 700;
   font-size: 50px;
   line-height: 60px;
   color: #000000;
   padding-top: 3rem;
}

.banner_sub_text{
   font-style: normal;
   font-size: 14px;
}

.banner_message {
   font-style: normal;
   font-weight: 400;
   font-size: 20px;
   line-height: 30px;
   color: #000000;
}

.banner_button {
   display: flex;
   flex-direction: row;
   gap: 16px;
}

.get_started {
   background: #000000;
   color: #ffffff;
   width: 145px;
   height: 46px;
}

.browse_courses {
   background: none;
   color: #000000;
   width: 177px;
   height: 46px;
}

.banner_info_proc {
   display: flex;
   flex-direction: row;
   gap: 40px;
   padding-top: 10px;
   padding-bottom: 2rem;
}
.block_inf {
   max-width: 104px;
   display: flex;
   flex-direction: column;
}
.numbers_inf {
   font-style: normal;
   font-weight: 700;
   font-size: 28px;
   line-height: 36px;
   color: #000000;
}

.block_inf p {
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 20px;
   color: #333333;
}

.banner_photo img {
   margin-bottom: -5px;
}

@media only screen and (max-width: 1200px) {
   .section_no_background {
      width: calc(100vw - 24px);
   }
}

@media only screen and (max-width: 992px) {
   .section_no_background {
      width: 100vw;
   }
   .top_banner {
      background-image: none;
      background-color: #f8d210;
      flex-direction: column;
      align-items: center;
      padding: 64px 16px 0 16px;
      margin-top: 70px;
   }
   .banner_info {
      width: 343px;
   }
   .banner_title {
      font-size: 35px;
      line-height: 42px;
      padding-top: 0px;
      font-weight: 700;
   }

   .banner_message {
      font-size: 18px;
      line-height: 27px;
      font-weight: 400;
   }
   .banner_button {
      justify-content: space-between;
   }
   .banner_info_proc {
      justify-content: space-between;
      padding-top: 16px;
      padding-bottom: 0px;
   }
   .banner_photo {
      position: static;
      width: 375px;
    height: 312px;
    align-content: flex-end;
    -webkit-writing-mode: vertical-lr;
      overflow: hidden;
      background-image: url('../../../images/bg_mobile.png');
      background-position: bottom center;
      background-repeat: no-repeat;
   }
   .banner_photo img {
      width: 100%;
      height: 100%;
   }
}
