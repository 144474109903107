.section_black_background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 372px;
  background-color: #000000;
}

 @media only screen and (max-width: 992px) {
  .section_black_background{
    height: fit-content;
    padding: 1rem;
  }
  }

  .student_remarks {
  width: 818px;
  height: 212px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
 @media only screen and (max-width: 992px) {
  .student_remarks{
    width: 343px;
    height: 100%;
    flex-direction: column;
    flex-flow: column-reverse;
    justify-content: center;
    gap: 32px;
  }
  }

  .remarks_text_and_link {
  width: 100%;
  max-width: 493px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
 @media only screen and (max-width: 992px) {
  .remarks_text_and_link{
    gap: 24px;
  }
  }

  .yellow_linc {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-decoration-line: underline;
  color: #F8D210;
}
.remarks_text {
  width: 100%;
  max-width: 493px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: white;
}

  .test-span {
    font-weight: 700;
    font-size: 50px;
    line-height: 60px;
    color: #F8D210;
  }
  .avatar_block {
  width: 246px;
  height: 212px;
  position: relative;
  }

  .test-img {
      width: 100%;
    }

   .img_block {
    width: 184px;
    height: 196px;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    overflow: hidden;
    background-image: url("../../../images/bg/Ellipse114.png");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 181px 181px;
   }

   .label_block {
    position: absolute;
    bottom: -32px;
    right: 0;
    width: 221px;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 1px 3px 20px rgba(1, 1, 1, 0.07);
    border-radius: 5px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;}

     .label_title {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #1E1B2E;
    }
    .label_text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #333333;
      opacity: 0.8;
    }